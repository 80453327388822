<template>
    <button
        class="button-small"
        :disabled="disabled"
        :class="{
            'button-small__disabled': disabled,
            'button-small__pressed': pressed,
            [`button-small__${type}`]: true,
        }"
    >
        <div class="contents">
            <div class="text-wrapper">
                <img v-if="imageUrl" :src="imageUrl" class="icon" alt="" />
                {{ text }}
            </div>
        </div>
    </button>
</template>

<script>
import { ButtonType } from '@/student/ui/button/ButtonMedium.vue';

export default {
    name: 'ButtonSmall',
    props: {
        type: {
            type: String,
            default: ButtonType.Primary,
        },
        disabled: {
            default: false,
        },
        pressed: {
            default: false,
        },
        imageUrl: {
            default: false,
        },
        text: {
            default: 'TEXT',
        },
    },
};
</script>

<style lang="scss">
@import '../swatches.css';
.button-small {
    align-items: center;
    border: 2px solid $--Neutral-100;
    border-radius: 8px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    max-height: 36px;
    height: 100%;
    width: 100%;
    max-width: 92px;
    min-height: 36px;
    min-width: 92px;
    position: relative;
    cursor: pointer;
    box-sizing: border-box;
    background-color: $--Green-300;
    box-shadow:
        inset 0 4px 0 rgba(255, 255, 255, 0.25),
        inset 0px -4px 0px rgba(0, 0, 0, 0.25),
        0 0 0 2px $--Neutral-700,
        0px 2px 0px 2px rgba(0, 0, 0, 0.25);
}

.button-small .contents {
    align-items: center;
    border-radius: 8px;
    display: flex;
    height: 36px;
    justify-content: center;
    position: relative;
    width: 88px;
    box-sizing: border-box;
}

.button-small .text-wrapper {
    color: $--Green-700;
    font-family: $secondary-font-family;
    font-size: 24px;
    font-style: italic;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 24px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
    display: flex;
}

.button-small .icon {
    margin-right: auto;
    width: 20px;
    height: 20px;
}

.button-small__disabled {
    background-color: $--Neutral-300;
    cursor: default;
}

.button-small__disabled .text-wrapper {
    color: $--Neutral-500;
}

.button-small__pressed {
    background-color: $--Green-500;
}

.button-small__pressed .text-wrapper {
    color: $--Green-700;
}
.button-small__info {
    background-color: $--Blue-300;

    .text-wrapper {
        color: $--Blue-700;
    }
    &.button-small__pressed {
        background-color: $--Blue-500;

        .text-wrapper {
            color: $--Blue-700;
        }
    }
    &.button-small__disabled {
        background-color: $--Neutral-300;

        .text-wrapper {
            color: $--Neutral-500;
        }
    }
}
.button-small__secondary {
    background-color: $--Yellow-300;

    .text-wrapper {
        color: $--Yellow-900;
    }
    &.button-small__pressed {
        background-color: $--Yellow-500;

        .text-wrapper {
            color: $--Yellow-900;
        }
    }
    &.button-small__disabled {
        background-color: $--Neutral-300;

        .text-wrapper {
            color: $--Neutral-500;
        }
    }
}

.button-small__destructive {
    background-color: $--Red-300;

    .text-wrapper {
        color: $--Red-700;
    }
    &.button-small__pressed {
        background-color: $--Red-500;

        .text-wrapper {
            color: $--Red-700;
        }
    }
    &.button-small__disabled {
        background-color: $--Neutral-300;

        .text-wrapper {
            color: $--Neutral-500;
        }
    }
}

.button-small__tertiary {
    background-color: #7b43c0;

    .text-wrapper {
        color: white;
    }

    &:hover,
    &.button-small__pressed {
        background-color: #5f2f99;

        .text-wrapper {
            color: white;
        }
    }
    &.button-small__disabled {
        background-color: $--Neutral-300;

        .text-wrapper {
            color: $--Neutral-500;
        }
    }
}
</style>
