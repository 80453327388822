export const MiniGamesRoutes = [
    {
        path: '/mini-games/stack-game',
        name: 'mini-games.stack-game',
        component: () => import('./StackGame/StackGame.vue'),
    },
    {
        path: '/mini-games/crossy-road-game',
        name: 'mini-games.crossy-road-game',
        component: () => import('./CrossyRoadGame/CrossyRoadGame.vue'),
    },
];
