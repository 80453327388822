/* global mixpanel */
export const MONTHLY_COMPETITION_MP_EVENTS = {
    MONTHLY_COMPETITION_VIEWS_COMPETITION_LANDING_PAGE:
        'viewsCompetitionLandingPage',
    MONTHLY_COMPETITION_VIEWS_COMPETITION_REGISTER_PAGE:
        'viewsCompetitionRegisterPage',
    MONTHLY_COMPETITION_REGISTERS_CLASS: 'registersClass',
    MONTHLY_COMPETITION_EDITS_CLASS: 'editsClass',
    STUDENT_NAVIGATES_TO_COMPETITION: 'studentNavigatesToLeaderboard',
};

export const MONTHLY_COMPETITION_METHODS = {
    viewsCompetitionLandingPage() {
        mixpanel.track('Views competition landing page');
    },
    viewsCompetitionRegisterPage() {
        mixpanel.track('Views competition register page');
    },
    registersClass({
        classCode,
        grade,
        state,
        schoolName,
        registrationDate,
        publicClassName,
    }) {
        mixpanel.track('Teacher registers class in competition', {
            'Class ID': classCode,
            'Grade Level': grade,
            State: state,
            'School Name': schoolName,
            'Registration Date': registrationDate,
            'Public Class Name': publicClassName,
        });
    },
    editsClass({ classCode, grade, state, schoolName, publicClassName }) {
        mixpanel.track('Teacher edits class in competition', {
            'Class ID': classCode,
            'Grade Level': grade,
            State: state,
            'School Name': schoolName,
            'Public Class Name': publicClassName,
        });
    },
    studentNavigatesToLeaderboard({ classCode }) {
        mixpanel.track('Student clicks on competition leaderboard', {
            'Class ID': classCode,
        });
    },
};
