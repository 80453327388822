// @TODO: game modes can be refactored
const CONSTANTS = Object.freeze({
    SCORE_MULTIPLIER: 10,
    CLASS_SCORE_MULTIPLIER: 15,
    LIVE_GAME_JOIN_CODE_LENGTH: 6,
    SELF_PACED_GAME_JOIN_CODE_LENGTH: 8,
    LIVE_GAME_PERMANENT_CODE_LENGTH: 27,
    SELF_PACED_GAME_PERMANENT_CODE_LENGTH: 29,
    MATH_RUNNER_GAME_CODE_LENGTH: 4,
    LIVE_GAME_MODE: 'tbl',
    SELF_PACED_GAME_MODE: 'spg',
    SELF_PACED_PLAYLIST_MODE: 'self-paced-playlist',
    MATH_RUNNER_GAME_MODE: 'runner',
    SOLO_GAME_MODE: 'solo',
    SOLO_TRACK_GAME_MODE: 'solo-track',
    FLUENCY_GAME_MODE: 'fluency',
    ONE_V_ONE_GAME_MODE: '1v1',
    ACCURACY_GAME_MODE: 'accuracy',
    CLIENT_TYPES: {
        HOST: 'host',
        PLAYER: 'player',
    },
    CREATOR_ROLE: {
        TEACHER: 'teacher',
    },
    LIVE_GAME_SECONDS_PER_TASK: 2.3, // avg calculated from 5k live games.
    SPG_GAME_SECONDS_PER_TASK: 0.5, // possible solving time for superimpressive students playing SPG.
    CACHE_SAVE_DELAY_IN_MS: 5000, // store caching "debounce"
    TBL_TIME_TO_CLOSE_SOCKET: 5 * 60 * 1000, // time to close socket in tbl game after game finished
    TBL_QUESTIONS_SHUFFLE_SLICE_SIZE: 5, //shuffle batch size to tbl
    TEST_GROUPS: {
        GROUP_1: [
            'USA - New Jersey',
            'USA - Michigan',
            'USA - Virginia',
            'USA - Alabama',
            'USA - Arizona',
        ],
        GROUP_2: [
            'USA - Pennsylvania',
            'USA - South Carolina',
            'USA - Arkansas',
            'USA - Oklahoma',
        ],
        GROUP_3: [
            'USA - New York',
            'USA - Wisconsin',
            'USA - Tennessee',
            'USA - Louisiana',
            'USA - Nebraska',
            'USA - Minnesota',
        ],
        GROUP_4: ['USA - Ohio', 'USA - Kentucky', 'USA - Kansas', 'USA - Utah'],
        GROUP_5: [
            'USA - Illinois',
            'USA - Florida',
            'USA - Iowa',
            'USA - Colorado',
        ],
        GROUP_6: [
            'USA - Indiana',
            'USA - Georgia',
            'USA - Missouri',
            'USA - Nevada',
            'USA - Idaho',
        ],
        EXTRA_GROUP: ['USA - North Carolina'],
    },
    WHERE_CLICKED_PLAY_BUTTON_OPTIONS: {
        RECENTLY_PLAYED_SECTION: 'Recently played section',
        SUGGESTED_FOR_INDIVIDUAL_PRACTICE_SECTION:
            'Suggested for individual practice section',
        FROM_PICKING_A_SKILL_IN_SKILL_LIST:
            'From picking a skill in skill list',
        SUGGESTED_GAMES_BLOCK: 'Suggested games block',
        NON_US_TOPIC_CONFIGURATION: 'Non-US topic configuration',
        NON_US_RECENTLY_PLAYED_SECTION: 'Non-US Recently played section',
        REPEAT_GAME: 'Repeat game',
    },
    PET_LEVEL_DELIMITER: '_level_',
});

export default CONSTANTS;
