/* global mixpanel */
export const CLASS_WORLDS_EVENTS = {
    CLICKED_CLASS_WORLD_LEADERBOARD: 'clickedClassWorldLeaderboard',
    CLASS_SELECTOR_CHANGED_CLASS: 'classSelectorChangedClass',
    LEVELED_UP_CLASS_WORLD: 'leveledUpClassWorld',
    PLAYER_LEVELED_UP: 'playerLeveledUp',
};

export const CLASS_WORLDS_METHODS = {
    clickedClassWorldLeaderboard() {
        mixpanel.track('Clicked Class World leaderboard');
    },
    classSelectorChangedClass() {
        mixpanel.track('Student changed class with class selector');
    },
    leveledUpClassWorld({ level, mBucks }) {
        mixpanel.track('Leveled up class world', {
            'Leveled up class world': level,
            MBucks: mBucks,
        });
        mixpanel.register({
            MBucks: mBucks,
        });
        mixpanel.people.set({
            MBucks: mBucks,
        });
    },
    playerLeveledUp({ level, mBucks }) {
        mixpanel.track('Player leveled up', {
            'New level': level,
            MBucks: mBucks,
        });
        mixpanel.register({
            'Battle pass level': level,
            MBucks: mBucks,
        });
        mixpanel.people.set({
            'Battle pass level': level,
            MBucks: mBucks,
        });
    },
};
