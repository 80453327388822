/* global mixpanel */
export const ONE_V_ONE_EVENTS = {
    CLICKED_START_1V1: 'clickedStart1v1',
    CHOSE_IF_WITH_BOOSTERS_1V1: 'choseIfWithBoosters',
    CHOSE_BOOSTERS_1V1: 'choseBoosters',
    FINISHED_ROUND_1_1V1: 'finishedRound1',
    FINISHED_GAME_1V1: 'finishedGame',
    CLICKED_PLAY_AGAIN_1V1: 'clickedPlayAgainIn1v1',
};

export const ONE_V_ONE_METHODS = {
    clickedStart1v1({ level }) {
        mixpanel.track('Clicked on start 1v1', {
            Level: level,
        });
    },
    choseIfWithBoosters({ answer }) {
        mixpanel.track('Chose in booster option in 1v1', {
            Answer: answer,
        });
    },
    choseBoosters({ boosters }) {
        mixpanel.track('Chose boosters in 1v1', {
            Boosters: boosters,
        });
    },
    finishedRound1() {
        mixpanel.track('Finished round 1 in 1v1');
    },
    finishedGame() {
        mixpanel.track('Finished 1v1 Game');
    },
    clickedPlayAgainIn1v1() {
        mixpanel.track('Clicked Play Again in 1v1');
    },
};
