import Api from '@/core/services/Api';
import { waitSec } from '@/core/helpers/utils';
import TopicsFactory from '@/core/math-topics/TopicsFactory';
import {
    EVENTS,
    TrackingService,
} from '@/core/services/TrackingService/TrackingService';
import SecureApi from '@/flows/Authentication/services/SecureApi';
import moment from 'moment';
import getSoloTrackData from '@/core/static-json/soloTrackData2.json';
import CONSTANTS from '@/core/helpers/constants';
import { getCardReward, getChestReward } from '@/student/helpers.js';
import { v4 as uuidv4 } from 'uuid';

export const SOLO_TRACK_AVAILABLE_TOPICS = [
    'TYPE_SUBTRACTION',
    'TYPE_ADDITION',
    'TYPE_MULTIPLICATION',
    'TYPE_DIVIDING',
];

// fluency is tasks per minute
export const worldsData = [
    {
        id: 1,
        round: 1,
        accuracyGoal: 85,
        accuracyAnswersGoal: 6,
        isAccuracy: true,
    },
    {
        id: 2,
        round: 4,
        fluencyGoal: 3,
        isFluency: true,
    },
    {
        id: 3,
        round: 7,
        fluencyGoal: 4,
        isFluency: true,
    },
    {
        id: 4,
        round: 11,
        fluencyGoal: 4,
        isFluency: true,
    },
    {
        id: 5,
        round: 12,
        fluencyGoal: 5,
        isFluency: true,
    },
    {
        id: 6,
        round: 13,
        fluencyGoal: 6,
        isFluency: true,
    },
    {
        id: 7,
        round: 14,
        fluencyGoal: 8,
        isFluency: true,
    },
    {
        id: 8,
        round: 15,
        fluencyGoal: 10,
        isFluency: true,
    },
    {
        id: 9,
        round: 16,
        fluencyGoal: 12,
        isFluency: true,
    },
    {
        id: 10,
        round: 17,
        fluencyGoal: 15,
        isFluency: true,
    },
    {
        id: 11,
        round: 18,
        fluencyGoal: 17,
        isFluency: true,
    },
    {
        id: 12,
        round: 19,
        fluencyGoal: 20,
        isFluency: true,
    },
];

const store = {
    namespaced: true,
    state: {
        grade: 1,
        math: null,
        topic: null,
        skill: null,
        round: null,
        stats: null,
        result: null,
        questions: [],
        studentSoloTrackData: null,
        currentSoloTrack: [],
        currentSoloTrackFromDate: [],
        trackProgramIsDone: false,
        selectedStudent: null,
        selectedStudentAllTracksData: null,
        lastSevenDaysStudentStatistics: null,
        pending: false,
        studentGames: [],
        onlineStudents: [],
        studentCounterData: [],
        selectedStatsTab: 'today',
        isFluencyGame: false,
        isClaimModalOpen: false,
        showClaimedItem: false,
        canCloseClaimModal: false,
        claimedItem: null,
        classHomeData: [],
    },
    getters: {
        studentSoloTrackData: (state) => {
            return state.studentSoloTrackData;
        },
        skill: (state) => {
            return state.skill;
        },
        stats: (state) => {
            return state.stats;
        },
        gameAccuracy: (state) => {
            if (state?.stats?.correctAnswers && state?.stats?.answers) {
                return (
                    Math.round(
                        (state.stats.correctAnswers /
                            state.stats.answers.length) *
                            100,
                    ) || 0
                );
            } else {
                return 0;
            }
        },
        currentSoloTrack: (state) => {
            return state.currentSoloTrack;
        },
        currentSoloTrackFromDate: (state) => {
            return state.currentSoloTrackFromDate;
        },
        trackProgramIsDone: (state) => {
            return state.trackProgramIsDone;
        },
        selectedStudent: (state) => {
            return state.selectedStudent;
        },
        selectedStudentAllTracksData: (state) => {
            return state.selectedStudentAllTracksData;
        },
        lastSevenDaysStudentStatistics: (state) => {
            return state.lastSevenDaysStudentStatistics;
        },
        pending: (state) => {
            return state.pending;
        },
        getAccuracyColor: () => {
            return (value) =>
                value === 0
                    ? 'neutral'
                    : value <= 50
                      ? 'red'
                      : value < 90
                        ? 'neutral'
                        : 'green';
        },
        getGameResult: (state) => {
            return state.result;
        },
        getFilteredOnlineStudents: (state) => {
            return (currentTime) => {
                return state.onlineStudents.filter((r) => {
                    const data = r.lastGameAt;

                    if (!data) {
                        return false;
                    }

                    return (
                        data &&
                        currentTime.diff(moment(data), 'minutes', true) <= 2
                    );
                });
            };
        },
        isFluencyGame: (state) => {
            return state.isFluencyGame;
        },
        calculateFluencyLevel() {
            return (secPerTask) => {
                if (secPerTask === 0) {
                    return 0;
                }

                const levelList = {
                    level1: 20,
                    level2: 6,
                    level3: 3,
                };

                if (secPerTask > levelList.level1) {
                    return 0;
                }

                if (secPerTask > levelList.level2) {
                    return 1;
                }

                if (
                    secPerTask <= levelList.level2 &&
                    secPerTask > levelList.level3
                ) {
                    return 2;
                }

                if (secPerTask <= levelList.level3) {
                    return 3;
                }

                return 0;
            };
        },
        calculateSkillFluencyLevel(_, getters) {
            return (skill) =>
                getters.calculateFluencyLevel(
                    getters.calculateSkillFluency(skill),
                );
        },
        calculateSkillFluency() {
            return (skill) => {
                if (skill) {
                    const timeSpent = skill.lastTimeSpent || skill.timeSpent;

                    return (timeSpent / skill.solvedProblems).toFixed(1);
                } else {
                    return 0;
                }
            };
        },
        round: (state) => {
            return state.round;
        },
        currentSkillList(_, getters) {
            const currentTopic = getSoloTrackData
                .find(
                    (perGrade) =>
                        perGrade.grade === getters.studentSoloTrackData?.grade,
                )
                ?.programs?.find(
                    (item) =>
                        item.topic === getters.studentSoloTrackData?.topic,
                );

            return (
                currentTopic?.skills.map((skill) => ({
                    ...skill,
                    fluency: currentTopic.fluency,
                })) || []
            );
        },
        claimedItem: (state) => {
            return state.claimedItem;
        },
        flatProgramData: (_, getters) => {
            const data = worldsData.flatMap((world) => {
                const arr = getters.currentSkillList.flatMap((skill) => {
                    return {
                        world,
                        skill,
                    };
                });

                arr.push({
                    world,
                    rewardId: `${getters.studentSoloTrackData?.topic} - Reward ${world.id}`,
                    isRewardNode: true,
                });

                return arr;
            });

            let isActiveSkillFound = false;

            return data.map((item, index) => {
                const defaultData = {
                    ...item,
                    id: index + 1,
                    isCompleted: false,
                    isLastActiveNode: false,
                };

                if (isActiveSkillFound) {
                    return defaultData;
                }

                if (item.isRewardNode) {
                    const isClaimed =
                        !!getters.studentSoloTrackData?.claimedRewards?.find(
                            (reward) => reward.rewardId === item.rewardId,
                        );

                    isActiveSkillFound = !isClaimed;

                    return {
                        ...defaultData,
                        isCompleted: isClaimed,
                        isLastActiveNode: !isClaimed,
                    };
                } else {
                    const skillInST =
                        getters.studentSoloTrackData?.skills?.find(
                            (skill) => skill.skillName === item.skill?.name,
                        );

                    if (!skillInST) {
                        isActiveSkillFound = true;

                        return {
                            ...defaultData,
                            isLastActiveNode: true,
                        };
                    }

                    const roundInST = skillInST?.rounds?.find(
                        (round) =>
                            round.roundName === `Round ${item.world?.round}`,
                    );

                    if (!roundInST) {
                        isActiveSkillFound = true;

                        return {
                            ...defaultData,
                            isLastActiveNode: true,
                        };
                    } else {
                        const isCompleted = item.world.isFluency
                            ? roundInST.bestFluency >= item.world.fluencyGoal
                            : roundInST.bestAccuracy >= item.world.accuracyGoal;

                        isActiveSkillFound = !isCompleted;

                        return {
                            ...defaultData,
                            isCompleted,
                            isLastActiveNode: !isCompleted,
                        };
                    }
                }
            });
        },
        classHomeData: (state) => {
            return state.classHomeData;
        },
    },
    mutations: {
        setSkill: (state, skill) => {
            state.skill = skill;
        },
        setRound: (state, round) => {
            state.round = round;
        },
        chooseTopic: (state, topic) => {
            state.topic = topic;

            sessionStorage.setItem('topicSaved', JSON.stringify(topic));
        },
        changeGrade: (state, grade) => {
            state.grade = grade;
        },
        initGame: (
            state,
            { skill, round, math, questions, stats = null, isFluencyGame },
        ) => {
            state.round = round;

            state.skill = skill;

            state.math = math;

            state.questions = questions;

            state.stats = stats || {
                playedTime: 0,
                started: new Date(),
                correctAnswers: 0,
                wrongAnswers: 0,
                answers: [],
            };

            state.result = null;

            state.isFluencyGame = isFluencyGame;
        },
        setGameStats: (state, stats) => {
            console.log('store::solo-track::setGameStats', stats);

            state.stats = stats;
        },
        finishGame: (state) => {
            if (!state.stats) return;

            state.stats.playedTime = Math.floor(
                (new Date().getTime() -
                    new Date(state.stats.started).getTime()) /
                    1000,
            );
        },
        setGameResult: (state, next) => {
            state.result = next;
        },
        setStudentSoloTrackData: (state, next) => {
            state.studentSoloTrackData = next;
        },
        setCurrentSoloTrack: (state, next) => {
            state.currentSoloTrack = next;
        },
        setCurrentSoloTrackFromDate: (state, next) => {
            state.currentSoloTrackFromDate = next;
        },
        setSoloTrackStudent: (state, student) => {
            const index = state.currentSoloTrack.findIndex(
                (track) => track.userId === student.userId,
            );

            if (index > -1) {
                state.currentSoloTrack[index] = student;
            } else {
                state.currentSoloTrack.push(student);
            }
        },
        setTrackProgramIsDone: (state, next) => {
            state.trackProgramIsDone = next;

            if (next) {
                state.studentSoloTrackData = null;
            }
        },
        setSelectedStudent: (state, next) => {
            state.selectedStudent = next;
        },
        setSelectedStudentAllTracksData: (state, next) => {
            state.selectedStudentAllTracksData = next;
        },
        setLastSevenDaysStudentStatistics: (state, next) => {
            state.lastSevenDaysStudentStatistics = next;
        },
        setPending: (state, next) => {
            state.pending = next;
        },
        setStudentGames: (state, games) => {
            state.studentGames = games;
        },
        setOnlineStudents: (state, students) => {
            state.onlineStudents = students;
        },
        setOnlineStudent: (state, data) => {
            const studentData = {
                skill: data.metadata.skill,
                lastGameAt: data.metadata.lastGameAt,
                lastActivityAt: data.metadata.lastActivityAt,
                userInfo: data.userInfo,
            };

            const index = state.onlineStudents.findIndex(
                (track) =>
                    track.userInfo.userId === studentData.userInfo.userId,
            );

            if (index > -1) {
                state.onlineStudents[index] = studentData;
            } else {
                state.onlineStudents.push(studentData);
            }
        },
        setStudentCounterData: (state, data) =>
            state.studentCounterData.push({ ...data, time: new Date() }),
        clearStudentCounterData: (state) => (state.studentCounterData = []),
        setSelectedStatsTab: (state, next) => (state.selectedStatsTab = next),
        setIsFluencyGame: (state, next) => {
            state.isFluencyGame = next;
        },
        setClaimedItem: (state, next) => {
            state.claimedItem = next;
        },
        setShowClaimedItem: (state, next) => {
            state.showClaimedItem = next;
        },
        setClaimModalOpen: (state, next) => {
            state.isClaimModalOpen = next;
        },
        setCanCloseClaimModal: (state, next) => {
            state.canCloseClaimModal = next;
        },
        setClassHomeData: (state, next) => {
            state.classHomeData = next;
        },
    },
    actions: {
        cleanSkillAndRound: (store) => {
            store.commit('setSkill', null);
            store.commit('setRound', null);
        },
        init: async (store) => {
            const user = store.rootGetters?.user;

            if (
                user?.role === 'student' &&
                (user?.premium?.status === 'active' ||
                    user?.premium?.status === 'trial')
            ) {
                await store.dispatch('fetchSoloTrackData');
            }
        },
        saveCache: (store) => {
            const cache = {
                ...store.state,
            };

            sessionStorage.setItem('cache/solo-track', JSON.stringify(cache));
        },
        loadCache: (store) => {
            const jcache = sessionStorage.getItem('cache/solo-track');

            if (!jcache) return;

            let cache = null;

            try {
                cache = JSON.parse(jcache);
            } catch (err) {
                cache = null;

                console.error('store::solo-track loading cache error', err);
            }

            if (!cache) return;

            store.commit('changeGrade', cache.grade);

            store.commit('chooseTopic', cache.topic);

            const math = TopicsFactory.getTopicObject(cache.skill);

            store.commit('initGame', {
                skill: cache.skill,
                round: cache.round,
                math,
                questions: cache.questions,
                stats: cache.stats,
                isFluencyGame: cache.isFluencyGame,
            });

            store.commit('setGameResult', cache.result);
        },
        clearCache: () => {
            sessionStorage.removeItem('cache/solo-track');
        },
        resetState: (store, gameOnly = false) => {
            if (!gameOnly) {
                store.commit('changeGrade', 1);
                store.commit('chooseTopic', null);
            }

            store.commit('initGame', {
                skill: null,
                round: null,
                math: null,
                questions: [],
                stats: null,
            });

            store.commit('setGameResult', null);

            store.commit('setStudentSoloTrackData', null);

            store.commit('setCurrentSoloTrack', []);

            store.dispatch('clearCache');

            if (gameOnly) {
                store.dispatch('saveCache');
            }
        },
        checkGradeRestore: async (store) => {
            const user = store.rootGetters['user'];

            const gradeSaved =
                store.getters.studentSoloTrackData?.grade ||
                store.rootGetters['v2/user/activeClass']?.grade ||
                user?.studentInfo?.gradeInfo?.grade ||
                1;

            let activeGrade = 1;

            if (gradeSaved) {
                if (gradeSaved === 'K') {
                    activeGrade = 1;
                } else if (
                    gradeSaved === 'High school' ||
                    gradeSaved === 'I am an adult' ||
                    gradeSaved === 'Adult'
                ) {
                    activeGrade = 6;
                } else if (Number(gradeSaved) && Number(gradeSaved) > 6) {
                    activeGrade = 6;
                } else {
                    activeGrade = Number(gradeSaved);
                }
            }

            if (store.state.grade !== activeGrade) {
                store.commit('changeGrade', activeGrade);
            }
        },
        checkTopicRestore: async (store) => {
            const activeTopic = sessionStorage.getItem('topicSaved')
                ? JSON.parse(sessionStorage.getItem('topicSaved'))
                : null;

            if (!activeTopic) return;

            if (store.state.topic) return;

            store.commit('chooseTopic', activeTopic);
        },
        checkGameRestore: (store) => {
            if (store.state.stats && store.state.questions.length) {
                return true;
            }

            store.dispatch('loadCache');

            return store.state.stats && store.state.questions.length;
        },
        startSkillGame: async (store, { skill, round, isFluencyGame }) => {
            const topic = store.state.topic;

            const math = TopicsFactory.getTopicObject({ ...skill });

            const currentGrade = store.state.grade;

            if (!math) {
                return false;
            }

            store.commit('setIsFluencyGame', isFluencyGame);

            let questionsCount = 7;

            if (isFluencyGame) {
                // generate more questions for fluency game
                questionsCount = 100;
            }

            const questions = math.generateQuestions(questionsCount);

            console.log(
                'store::solo-track::startSkillGame',
                skill,
                round,
                math,
                topic,
                questions,
            );
            const name = topic?.name || skill.name;

            store.commit('initGame', {
                skill: {
                    ...skill,
                    topic: name,
                },
                round,
                math,
                questions,
                isFluencyGame,
            });

            try {
                const response = await Api().post('solo-track/game/start', {
                    skill: {
                        ...store.state.skill,
                        topic: store.getters.studentSoloTrackData.topic,
                    },
                    classCode: store.rootGetters['v2/user/getClassCode'],
                });

                const { success, data } = response.data;

                if (success) {
                    console.info(data);
                }
            } catch (e) {
                console.error(e);
            }

            // just to see a bit of loading animation :)
            await waitSec(0.1);

            const user = store.rootGetters.user;

            new TrackingService(user).track(EVENTS.STG_STARTED_PLAYING, {
                taskType: skill.type,
                taskScale: name,
                creatorRole: user.role,
            });

            sessionStorage.setItem(
                'solo-track-currect-game',
                JSON.stringify({
                    topic,
                    skill,
                    round,
                }),
            );

            sessionStorage.setItem(
                'solo-track-last-played-skill',
                JSON.stringify({ skill, round, currentGrade }),
            );

            // Save current xp to sessinStorage
            sessionStorage.setItem(
                'homeGameV10SimpleTreeSocial-last-xp',
                Math.round(user.studentInfo?.homeGameV10SimpleTreeSocial?.xp) ||
                    0,
            );

            store.dispatch('saveCache');

            return true;
        },
        gameAnswer: (store, stats) => {
            store.commit('setGameStats', stats);

            store.dispatch('saveCache');
            if (
                !store.rootGetters.isBefore3PM &&
                store.state.stats.answers.length === 1
            ) {
                console.debug('CONSUME 1 ENERGY');

                // 1st answer - reduce energy
                store.dispatch('v2/homegame/consumeEnergy', { root: true });
            }

            return stats.answers[stats.answers.length - 1].correct;
        },
        endGame: async (store, stats) => {
            store.commit('setGameStats', stats);

            store.commit('finishGame');

            const playedTime = store.state.isFluencyGame
                ? 60
                : moment().diff(moment(store.getters.stats.started), 'seconds');

            const gameData = {
                skill: {
                    ...store.state.skill,
                    roundName: `Round ${store.state.round}`,
                    topic: store.getters.studentSoloTrackData.topic,
                },
                stats: {
                    correctAnswers: store.getters.stats.correctAnswers,
                    wrongAnswers: store.getters.stats.wrongAnswers,
                    playedTime,
                    answers: store.getters.stats.answers,
                },
                gameMode: store.getters.isFluencyGame
                    ? CONSTANTS.FLUENCY_GAME_MODE
                    : CONSTANTS.SOLO_TRACK_GAME_MODE,
                classCode: store.getters.studentSoloTrackData.classCode,
            };

            let result = null;

            try {
                const response = await Api().post(
                    'solo-track/game/end',
                    gameData,
                );

                const { success, data } = response.data;

                if (success) {
                    result = data.endGameStats;
                    store.dispatch('v2/user/update', data, { root: true });
                }
            } catch (e) {
                console.error(e);
            }

            store.dispatch('fetchSoloTrackData');

            const user = store.rootGetters['user'];

            new TrackingService(user).track(EVENTS.ST_GAME_FINISHED, {
                problemsSolved: gameData.stats.correctAnswers,
            });

            store.commit('setGameResult', result);

            store.dispatch('saveCache');

            return !!result;
        },
        fetchSoloTrackData: async (store) => {
            try {
                let activeClassCode = store.rootState.v2.user.activeClassCode;

                if (!activeClassCode || activeClassCode === '-1') {
                    activeClassCode = store.rootGetters['v2/user/getClassCode'];
                }

                if (!activeClassCode) {
                    store.commit('setStudentSoloTrackData', null);

                    return;
                }

                const result = await SecureApi().get(
                    `solo-track/student/${activeClassCode}`,
                );

                const { success, data } = result.data;

                if (success) {
                    const changed = await store.dispatch(
                        'changeIfNotSetTopic',
                        {
                            data: [data],
                            isTeacher: false,
                        },
                    );

                    if (changed) {
                        data.topic = changed.topic;
                    }

                    store.commit('setPending', false);

                    store.commit('setStudentSoloTrackData', data);
                } else {
                    store.commit('setStudentSoloTrackData', null);
                }
            } catch (err) {
                console.error(err);
            }
        },
        fetchOnlineStudents: async (store) => {
            try {
                const activeClassCode =
                    store.rootGetters['v2/teacher/activeClass']?.classCode;

                if (!activeClassCode) return;

                const result = await SecureApi().get(
                    `solo-track/class/${activeClassCode}/online`,
                );

                const { success, data, error } = result.data;

                if (success) {
                    store.commit('setOnlineStudents', data);
                } else {
                    console.log(error);
                }
            } catch (err) {
                console.error(err);
            }
        },
        raiseTheTrackLevel: async (store) => {
            const activeClassCode = store.rootGetters['v2/user/getClassCode'];

            if (!activeClassCode) return;

            store.commit('setPending', true);

            const result = await SecureApi().get(
                `solo-track/student/${activeClassCode}`,
            );

            const { success, data, error } = result.data;

            if (success) {
                const changed = await store.dispatch('changeIfNotSetTopic', {
                    data: [data],
                    isTeacher: false,
                });

                if (changed) {
                    data.topic = changed.topic;
                }

                const allTrackTopics = getSoloTrackData
                    .find((perGrade) => perGrade.grade === data.grade)
                    .programs.map((item) => item.topic);

                const finishedTopics = data.finished || [];

                const remainsTopics = allTrackTopics.filter(
                    (item) => !finishedTopics.includes(item),
                );

                if (remainsTopics.length === 0) {
                    store.commit('setPending', false);

                    store.commit('setTrackProgramIsDone', true);
                } else {
                    const currentTrackIndex = allTrackTopics.findIndex(
                        (item) => item === data.topic,
                    );

                    let raiseIndex = currentTrackIndex + 1;

                    let nextTrack = allTrackTopics[raiseIndex];

                    let repeatCount = 0;

                    const repeatCountBoundary = 100;

                    if (!nextTrack) {
                        nextTrack = remainsTopics[0];
                    } else {
                        while (
                            !nextTrack ||
                            finishedTopics.includes(nextTrack)
                        ) {
                            if (raiseIndex <= allTrackTopics.length) {
                                repeatCount++;
                                raiseIndex++;
                                nextTrack = allTrackTopics[raiseIndex];

                                if (repeatCount >= repeatCountBoundary) {
                                    break;
                                }
                            } else {
                                repeatCount++;
                                raiseIndex = 1;
                                nextTrack = allTrackTopics[raiseIndex];

                                if (repeatCount >= repeatCountBoundary) {
                                    break;
                                }
                            }
                        }
                    }

                    if (repeatCount >= repeatCountBoundary) {
                        console.log('REPEAT COUNT BOUNDARY!!!');

                        return;
                    } else {
                        try {
                            const result = await SecureApi().patch(
                                'solo-track/student',
                                {
                                    classCode: data.classCode,
                                    finished: data.topic,
                                    topic: nextTrack,
                                },
                            );

                            const { success } = result.data;

                            if (success) {
                                const user = store.rootGetters.user;

                                new TrackingService(user).track(
                                    EVENTS.STUDENT_RAISE_SOLO_TRACK,
                                    {
                                        classCode: data.classCode,
                                        studentName: user.playerName,
                                        newTopic: nextTrack,
                                        oldTopic: data.topic,
                                    },
                                );

                                await store.dispatch('fetchSoloTrackData');
                            }
                        } catch (err) {
                            console.error(err);
                        }
                    }
                }
            } else {
                console.error(error);

                store.commit('setPending', false);
            }
        },
        resetProgramIsDone: async (store) => {
            store.commit('setTrackProgramIsDone', false);
        },
        seenLastChange: async (store, currentStudentTrack) => {
            const lastChange = currentStudentTrack.lastChange;

            if (!lastChange) {
                return false;
            }

            try {
                const result = await SecureApi().patch('solo-track/student', {
                    classCode: currentStudentTrack.classCode,
                    lastChange: {
                        seen: true,
                    },
                });

                const { success } = result.data;

                if (success) {
                    await store.dispatch('fetchSoloTrackData');
                }
            } catch (err) {
                console.error(err);
            }
        },
        changeIfNotSetTopic: async (store, { data, isTeacher = true }) => {
            const topicNotSet = data.find(({ topic }) => topic === 'Not set');

            const topic = getSoloTrackData.find(
                ({ grade }) => grade === topicNotSet?.grade,
            )?.programs[0]?.topic;

            if (!topic) {
                return;
            }

            let result;

            try {
                if (topicNotSet && isTeacher) {
                    result = await store.dispatch('changeSoloTrack', {
                        topic,
                        student: topicNotSet,
                        classCode: topicNotSet.classCode,
                        grade: topicNotSet.grade,
                    });
                }

                if (topicNotSet && !isTeacher) {
                    result = await SecureApi().patch('solo-track/student', {
                        topic,
                        classCode: topicNotSet.classCode,
                    });
                }

                if (result?.data?.success) return result?.data?.data;
            } catch (err) {
                console.error(err);
            }
        },
        getClassSoloTrack: async (store) => {
            try {
                const activeClass = store.rootGetters['v2/teacher/activeClass'];

                if (!activeClass || activeClass.classCode === '-1') {
                    return null;
                }

                const result = await SecureApi().get(
                    `/solo-track/class/${activeClass.classCode}?track=current&includeToResponse=rounds,answers,claimedRewards`,
                );

                const { success, data } = result.data;

                if (success) {
                    store.dispatch('changeIfNotSetTopic', {
                        data,
                    });

                    store.commit('setCurrentSoloTrack', data);
                } else if (result.data.error.code === 404 && activeClass) {
                    await store.dispatch('createClassSoloTrack');
                }
            } catch (err) {
                console.error(err);
            }
        },
        getClassSoloTrackFromDate: async (
            store,
            statsOption = 'today',
            updatedStudentId,
        ) => {
            try {
                const activeClass = store.rootGetters['v2/teacher/activeClass'];

                if (!activeClass || activeClass.classCode === '-1') {
                    return null;
                }

                const isToday =
                    (statsOption || store.state.selectedStatsTab) === 'today';

                const isYesterday =
                    (statsOption || store.state.selectedStatsTab) ===
                    'yesterday';

                const startDate = isToday
                    ? moment().startOf('day').toISOString()
                    : isYesterday
                      ? moment()
                            .subtract(1, 'days')
                            .startOf('day')
                            .toISOString()
                      : moment()
                            .subtract(7, 'days')
                            .startOf('day')
                            .toISOString();

                const endDate = moment().toISOString();

                const result = await SecureApi().get(
                    `/solo-track/class/${activeClass.classCode}?startDate=${startDate}&endDate=${endDate}&track=current&includeToResponse=rounds,answers,claimedRewards`,
                );

                const { success, data, error } = result.data;

                if (success) {
                    if (updatedStudentId) {
                        store.dispatch('changeIfNotSetTopic', {
                            data,
                        });

                        store.commit('setCurrentSoloTrack', data);
                    } else {
                        store.commit('setCurrentSoloTrackFromDate', data);
                    }
                } else if (error.code === 404) {
                    await store.dispatch('createClassSoloTrack');
                }
            } catch (err) {
                console.error(err);
            }
        },
        getStudentSoloTrack: async (store, userId) => {
            try {
                const result = await SecureApi().get(
                    `/solo-track/class/${store.rootGetters['v2/teacher/activeClass'].classCode}/${userId}?track=current&includeToResponse=rounds,answers,claimedRewards`,
                );

                const { success, data } = result.data;

                if (success) {
                    store.commit('setSoloTrackStudent', data[0]);
                }
            } catch (err) {
                console.error(err);
            }
        },
        createClassSoloTrack: async (store) => {
            try {
                const classGrade = `${store.rootGetters['v2/teacher/activeGrade']}`;

                const result = await SecureApi().post('/solo-track/class', {
                    classCode:
                        store.rootGetters['v2/teacher/activeClass'].classCode,
                    topic:
                        getSoloTrackData.find(
                            (perGrade) => perGrade.grade === classGrade,
                        )?.programs?.[0].topic || 'Error',
                    grade: classGrade,
                });

                const { success, data } = result.data;

                if (success) {
                    store.commit('setCurrentSoloTrack', data);
                }
            } catch (err) {
                console.error(err);
            }
        },
        changeSoloTrack: async (store, payload) => {
            try {
                const result = await SecureApi().patch(
                    `solo-track/student/${payload.student.userId}`,
                    {
                        classCode:
                            payload.classCode ||
                            store.rootGetters['v2/teacher/activeClass']
                                .classCode,
                        topic: payload.topic,
                    },
                );

                const { success } = result.data;

                if (success) {
                    const user = store.rootGetters.user;

                    const activeClass =
                        store.rootGetters['v2/teacher/activeClass'];

                    new TrackingService(user).track(
                        EVENTS.TEACHER_CHANGE_SOLO_TRACK,
                        {
                            classCode: activeClass.classCode,
                            className: activeClass.className,
                            student: payload.student,
                            newTopic: payload.topic,
                            oldTopic: payload.oldTopic,
                        },
                    );

                    store.dispatch('updateStudentSoloTrack', {
                        userId: payload.student.userId,
                        topic: payload.topic,
                    });
                }
            } catch (err) {
                console.error(err);
            }
        },
        updateStudentSoloTrack: async (store, payload) => {
            try {
                const activeClassCode =
                    store.rootGetters['v2/teacher/activeClass'].classCode;

                const startDate = moment()
                    .utc()
                    .set('year', 2023)
                    .set('month', 0)
                    .format();

                const endDate = moment().utc().format();

                const result = await SecureApi().get(
                    `solo-track/class/${activeClassCode}/${payload.userId}?startDate=${startDate}&endDate=${endDate}&groupBy=topic`,
                );

                const { success, data } = result.data;

                if (success) {
                    store.commit('setSelectedStudent', {
                        ...store.state.selectedStudent,
                        topic: payload.topic,
                    });

                    const updatedCurrentSoloTrack =
                        store.getters.currentSoloTrack.map((item) => {
                            if (item.userId === data[0].userId) {
                                return data[0];
                            } else {
                                return item;
                            }
                        });

                    store.commit(
                        'setCurrentSoloTrack',
                        updatedCurrentSoloTrack,
                    );
                }
            } catch (err) {
                console.error(err);
            }
        },
        bulkUpdateStudentSoloTracks: async (
            store,
            { studentIds, topic, grade },
        ) => {
            try {
                const activeClass = store.rootGetters['v2/teacher/activeClass'];

                const activeClassCode = activeClass.classCode;

                if (activeClassCode === '-1') return;

                const result = await SecureApi().patch(
                    `solo-track/students/${activeClassCode}`,
                    studentIds.map((userId) => ({ userId, topic, grade })),
                );

                const { success } = result.data;

                if (success) {
                    const user = store.rootGetters.user;

                    new TrackingService(user).track(
                        EVENTS.TEACHER_CHANGE_SOLO_TRACK_BULK,
                        {
                            classCode: activeClass.classCode,
                            className: activeClass.className,
                            studentIds,
                            newTopic: topic,
                            grade: grade,
                        },
                    );

                    await store.dispatch('getClassSoloTrack');
                }
            } catch (err) {
                console.error(err);
            }
        },
        async handleSelectStudent(store, info) {
            store.commit('setSelectedStudent', info);

            if (info) {
                await store.dispatch('getCurrentStudentTrackData', info);
                await store.dispatch('getLastSevenDaysStudentStatistics', info);
            } else {
                store.commit('setSelectedStudentAllTracksData', null);
                store.commit('setLastSevenDaysStudentStatistics', null);
            }
        },
        async getCurrentStudentTrackData(store, info) {
            try {
                const activeClass =
                    store.rootGetters['v2/teacher/activeClass'].classCode;

                const startDate = moment()
                    .utc()
                    .set('year', 2023)
                    .set('month', 0)
                    .format();

                const endDate = moment().utc().format();

                const result = await SecureApi().get(
                    `solo-track/class/${activeClass}/${info.userId}?startDate=${startDate}&endDate=${endDate}&track=all&includeToResponse=rounds,answers,claimedRewards`,
                );

                const { success, data } = result.data;

                if (success) {
                    store.commit('setSelectedStudentAllTracksData', data);
                }
            } catch (err) {
                console.error(err);
            }
        },
        async getHomeClassesActivities() {
            try {
                const startDate = moment().utc().subtract(7, 'days').format();

                const endDate = moment().utc().format();

                const result = await SecureApi().get(
                    `solo-track/activities/home?startDate=${startDate}&endDate=${endDate}`,
                );

                const { success, data } = result.data;

                if (success) {
                    return data;
                }
            } catch (err) {
                console.error(err);
            }
        },
        async getLastSevenDaysStudentStatistics(store, info) {
            try {
                const activeClass =
                    store.rootGetters['v2/teacher/activeClass'].classCode;

                const startDate = moment().utc().subtract(7, 'days').format();

                const endDate = moment().utc().format();

                const result = await SecureApi().get(
                    `solo-track/class/${activeClass}/${info.userId}?startDate=${startDate}&endDate=${endDate}&track=all&groupBy=date&includeToResponse=rounds,answers,claimedRewards`,
                );

                const { success, data } = result.data;

                if (success) {
                    store.commit('setLastSevenDaysStudentStatistics', data);
                }
            } catch (err) {
                console.error(err);
            }
        },
        getStudentSoloGames: async (store, studentId) => {
            try {
                const result = await SecureApi().get(
                    `solo-track/games/${studentId}`,
                );

                const { success, data, error } = result.data;

                if (success) {
                    store.commit('setStudentGames', data);
                } else {
                    console.log(error);

                    store.commit('setStudentGames', []);
                }
            } catch (err) {
                console.error(err);
            }
        },
        addOnlineStudent: (store, data) =>
            store.commit('setOnlineStudent', data),
        addStudentCounterData: (store, data) =>
            setTimeout(() => store.commit('setStudentCounterData', data), 0),
        clearStudentCounterData: (store) =>
            store.commit('clearStudentCounterData'),
        checkAndFixBrokenGradeAndTopicMapping: async (store) => {
            if (store.getters.trackProgramIsDone) {
                console.log('track is done');

                return;
            }

            const soloTrackData = store.getters.studentSoloTrackData;

            if (!soloTrackData?.grade) {
                // Load data before calling this method.
                console.log('no grade');

                return;
            }

            const gradeTopics = getSoloTrackData.find(
                (item) => item.grade === soloTrackData.grade,
            ).programs;

            if (
                gradeTopics.some((item) => item.topic === soloTrackData.topic)
            ) {
                // Topic exists in the grade, do nothing as it is correct.
                return false;
            }

            console.log('choose new');

            store.dispatch('raiseTheTrackLevel');
        },
        updateSelectedStatsTab: (store, value) =>
            store.commit('setSelectedStatsTab', value),
        createActivityLog({ rootGetters }, claimedItem) {
            const user = rootGetters['user'];

            const avatarData = rootGetters['v2/homegame/avatarData'];

            const playerName = rootGetters['v2/user/playerName'];

            if (['emoji-pack', 'background'].includes(claimedItem.type)) {
                // ignore activity of Emotes pack and background
                return false;
            }

            Promise.all(
                Object.keys(user?.studentInfo?.classLists || {}).map(
                    (classCode) =>
                        Api().post('activity-logs/create', {
                            name: claimedItem.avatar
                                ? 'gotNewAvatar'
                                : 'gotNewCard',
                            classCode,
                            playerName,
                            description: claimedItem.avatar
                                ? claimedItem.avatar.id
                                : claimedItem.name,
                            ...avatarData,
                        }),
                ),
            );
        },
        async openRewardNode(store, { skill, rewardName }) {
            store.commit('setShowClaimedItem', true);

            store.commit('setCanCloseClaimModal', false);

            const user = store.rootGetters['user'];

            let claimedItemData = null;

            const chestReward = getChestReward(user);

            if (chestReward) {
                claimedItemData = {
                    id: chestReward.id,
                    avatar: chestReward,
                    type: 'chest',
                };
            } else {
                const rewardIsCard = Math.random() > 0.5;

                if (rewardIsCard) {
                    const cardReward = getCardReward(user);

                    try {
                        const result = await Api().post(
                            `/home-game/cards/add`,
                            {
                                name: cardReward.name,
                                amount: 1,
                            },
                        );

                        const { success, data } = result.data;

                        if (success) {
                            store.dispatch(
                                'v2/user/update',
                                { studentInfo: data },
                                { root: true },
                            );
                        }

                        claimedItemData = {
                            id: cardReward.name,
                            ...cardReward,
                        };

                        store.dispatch('createActivityLog', cardReward);
                    } catch (e) {
                        console.log(e);
                    }
                } else {
                    claimedItemData = {
                        id: `emoji-pack-inf-${uuidv4()}`,
                        name: 'Emotes pack',
                        type: 'emoji-pack',
                    };

                    await Api().post(
                        `home-game-v10SimpleTreeSocial/battle-pass/claim/${claimedItemData.id}`,
                    );
                }
            }

            store.commit('setClaimedItem', claimedItemData);

            try {
                const result = await Api().post('solo-track/rewards/claim', {
                    classCode: store.getters.studentSoloTrackData.classCode,
                    skill,
                    reward: claimedItemData.id,
                    rewardId: rewardName,
                });

                const { data, success } = result.data;

                if (success) {
                    store.commit('setStudentSoloTrackData', {
                        ...store.getters.studentSoloTrackData,
                        claimedRewards: data.soloTrack?.claimedRewards,
                    });
                    store.dispatch('v2/user/update', data, { root: true });
                }
            } catch (e) {
                console.log(e);
            }

            store.commit('setClaimModalOpen', true);

            setTimeout(() => {
                store.commit('setCanCloseClaimModal', true);
            }, 900);

            return store.state.claimedItem;
        },
        async handleCloseClaimModal({ commit }) {
            commit('setShowClaimedItem', false);

            commit('setClaimModalOpen', false);

            commit('setClaimedItem', null);
        },
        createFlatProgramData: (store, { soloTrack }) => {
            const topic = getSoloTrackData
                .find((perGrade) => perGrade.grade === soloTrack?.grade)
                ?.programs?.find((item) => item.topic === soloTrack?.topic);

            const skillList =
                topic?.skills.map((skill) => ({
                    ...skill,
                    fluency: topic.fluency,
                })) || [];

            const data = worldsData.flatMap((world) => {
                const arr = skillList.flatMap((skill) => {
                    return {
                        world,
                        skill,
                    };
                });

                arr.push({
                    world,
                    rewardId: `${soloTrack?.topic} - Reward ${world.id}`,
                    isRewardNode: true,
                });

                return arr;
            });

            let isActiveSkillFound = false;

            return data.map((item, index) => {
                const defaultData = {
                    ...item,
                    id: index + 1,
                    isCompleted: false,
                    isLastActiveNode: false,
                };

                if (isActiveSkillFound) {
                    return defaultData;
                }

                if (item.isRewardNode) {
                    const isClaimed = !!soloTrack?.claimedRewards?.find(
                        (reward) => reward.rewardId === item.rewardId,
                    );

                    isActiveSkillFound = !isClaimed;

                    return {
                        ...defaultData,
                        isCompleted: isClaimed,
                        isLastActiveNode: !isClaimed,
                    };
                } else {
                    const skillInST = soloTrack?.skills?.find(
                        (skill) => skill.skillName === item.skill?.name,
                    );

                    if (!skillInST) {
                        isActiveSkillFound = true;

                        return {
                            ...defaultData,
                            isLastActiveNode: true,
                        };
                    }

                    const roundInST = skillInST?.rounds?.find(
                        (round) =>
                            round.roundName === `Round ${item.world?.round}`,
                    );

                    if (!roundInST) {
                        isActiveSkillFound = true;

                        return {
                            ...defaultData,
                            isLastActiveNode: true,
                        };
                    } else {
                        const isCompleted = item.world.isFluency
                            ? roundInST.bestFluency >= item.world.fluencyGoal
                            : roundInST.bestAccuracy >= item.world.accuracyGoal;

                        isActiveSkillFound = !isCompleted;

                        return {
                            ...defaultData,
                            isCompleted,
                            isLastActiveNode: !isCompleted,
                        };
                    }
                }
            });
        },
        async loadClassHomeData(store, { classCode }) {
            const result = await SecureApi().get(
                `/solo-track/class/${classCode}/played-today`,
            );

            const { data, success } = result.data;

            if (success) {
                const formattedData = data.map((item) => {
                    return {
                        ...item,
                        userInfo: {
                            name: item.name,
                        },
                        topic: 'Addition Skill Builders',
                    };
                });
                store.commit('setClassHomeData', formattedData);
            }
        },
    },
};
export default store;
