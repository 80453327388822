<template>
    <div v-if="showConnectionLost" class="connection connection--lost">
        <span>{{ $t('internetConnection.connectionLost') }}</span>
        <MathButton @click="showHowToFixModal">How can I fix this?</MathButton>
        <teleport v-if="showHowToFix" to="#app">
            <SuperAwesomeModal :x-close="true" @modalClosed="hideHowToFixModal">
                <div class="how-to-fix-modal-content">
                    <h2>How to fix the connection issue?</h2>
                    <p>
                        99math needs a websocket connection to communicate with
                        it's servers. At the moment this connection is not
                        working.
                    </p>
                    <h3>Troubleshooting steps</h3>
                    <ol>
                        <li>
                            Try using a different browser. Eg if you use Google
                            Chrome, try Microsoft Edge or Safari.
                        </li>
                        <li>
                            Now if from a different browser the issue remains,
                            then please do the following
                        </li>
                        <ul>
                            <li>
                                Go to
                                <router-link
                                    :to="{ name: 'health-check-page' }"
                                >
                                    99math's health check page
                                </router-link>
                            </li>
                            <li>
                                If there are some items in there that remain red
                                (everything should be green for the system to
                                work), then show this to your IT department
                            </li>
                            <li>
                                Most likely 99math needs to be whitelisted.
                                Information that they need is on that page.
                            </li>
                        </ul>
                        <li>If the IT department says everything is correct</li>
                        <ul>
                            <li>
                                Try accessing 99math from your phone to confirm
                                that this issue still happens on a different
                                network
                            </li>
                            <ul>
                                <li>
                                    If this does not happen on a different
                                    network or in a different account, then go
                                    to your IT department again. Something is
                                    wrong in the network.
                                </li>
                            </ul>
                            <li>
                                Ask another teacher to try with their account to
                                see if it's account specific issue
                            </li>
                        </ul>
                        <li>
                            If above does not help, reach out to us at
                            hello@99math.com sharing the issue and what you
                            already tried to fix it, as most likely then it's an
                            issue in our systems and the additional context
                            helps us figure the issue out quicker.
                        </li>
                    </ol>
                </div>
            </SuperAwesomeModal>
        </teleport>
    </div>
    <div
        v-else-if="showConnectionRestored"
        class="connection connection--restored"
    >
        {{ $t('internetConnection.connectionRestored') }}
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { RedirectPlayerIfNeededMixin } from '@/core/components/IsConnectedToBackend/mixins/RedirectPlayerIfNeededMixin';
import {
    CrashReportService,
    CrashReportTypes,
} from '@/core/services/CrashReportService';
import SuperAwesomeModal from '@/core/ui/Modal/SuperAwesomeModal.vue';
import MathButton from '@/core/ui/_legacy/MathComponents/MathButton.vue';

export default {
    components: { SuperAwesomeModal, MathButton },
    mixins: [RedirectPlayerIfNeededMixin],
    data() {
        return {
            showConnectionLost: false,
            showConnectionRestored: false,
            timeout: null,
            crs: new CrashReportService(null, this.$socket),
            v2reconnects: 0,
            showHowToFix: false,
        };
    },
    computed: {
        ...mapGetters({
            reconnectAttempts: 'getWebsocketReconnectionAttemptCount',
            currentSocket: 'v2/io/currentSocket',
            clientType: 'getClientType',
        }),
        shouldBeConnected() {
            return this.$v2
                ? this.currentSocket?.socket.open
                : this.$store.getters.shouldWebsocketBeConnected;
        },
        reconnects() {
            return this.currentSocket?.reconnects || 0;
        },
        socket() {
            return this.currentSocket?.socket;
        },
        open() {
            return this.currentSocket?.open;
        },
        connected() {
            return this.currentSocket?.connected;
        },
    },
    watch: {
        reconnects(reconnects) {
            console.log('ICTBVWS.watcher.socket reconnects', reconnects);

            if (reconnects > 2) {
                if (reconnects === 3) {
                    this.crs.sendCrashReport(
                        CrashReportTypes.SOCKET_NOT_CONNECTING,
                    );
                }
                this.showConnectionLost = true;
            }

            if (!reconnects && this.v2reconnects) {
                this.showConnectionLost = false;

                this.crs.ammendCrashReport(
                    CrashReportTypes.SOCKET_NOT_CONNECTING,
                    `reconnected after ${this.v2reconnects} attempts`,
                );

                this.checkIfUserShouldBeRedirectedNow();

                this.showConnectionLost = false;

                this.showConnectionRestored = true;

                this.timeout = setTimeout(() => {
                    this.showConnectionRestored = false;
                }, 3000);
            }

            this.v2reconnects = reconnects;
        },
        socket(socket) {
            console.log(
                'ICTBVWS.watcher.socket cached connection?',
                this.currentSocket?.cacheConnect,
            );

            if (!socket || !this.currentSocket?.cacheConnect) return;

            // console.log('ICTBVWS.watcher.socket cached connection!');
            socket.on('game-not-found', () => {
                console.log('ICTBVWS::io.game-not-found!');
                // come here because load cache works before initialization
                // and if previous cache contained game already not exists
                // in redis (expired CACHE_TTL_GAME=7200 = 2h in BE env) and mongo
                // so game was not played and removed as empty
                // so just clear cache (all = v2/io + v2/game)

                this.$store.dispatch('v2/io/leave');

                // reset also do cache clearing dispatch
                this.$store.dispatch('v2/game/reset');

                this.$router.push({ name: 'home' });
            });
            socket.on('game-finished', () => {
                console.log('ICTBVWS::io.game-finished!');

                this.$store.dispatch('v2/io/leave');

                this.checkIfUserShouldBeRedirectedNow();
            });
        },
        open(isOpen) {
            console.log('ICTBVWS.watcher.open', isOpen);

            if (!isOpen) {
                this.showConnectionLost = false;

                this.showConnectionRestored = false;

                if (this.timeout) {
                    clearTimeout(this.timeout);

                    this.timeout = null;
                }
            }
        },
        connected() {
            if (!this.open) return;

            this.v2reconnects = 0;

            console.log('ICTBVWS.watcher.connected io.open?', this.open);
        },
    },
    mounted() {
        this.socket?.on('reconnect_attempt', () => {
            // console.log('reconnect attempt event');
            if (this.shouldBeConnected) {
                this.$store.commit('increaseWebsocketReconnectCounter');

                if (this.reconnectAttempts > 2) {
                    this.crs.sendCrashReport(
                        CrashReportTypes.SOCKET_NOT_CONNECTING,
                    );

                    this.showConnectionLost = true;
                }
            }
        });

        this.socket?.on('reconnect', () => {
            this.$store.commit('setWebsocketConnectionStatus', true);

            this.ammendCrashReportNoSocketConnection();

            // No matter what, make sure that the user now is on
            // the correct view, since they had a slight moment
            // when they were not connected.
            this.checkIfUserShouldBeRedirectedNow();

            if (this.showConnectionLost) {
                this.showConnectionRestored = true;

                this.showConnectionLost = false;

                this.timeout = setTimeout(() => {
                    this.showConnectionRestored = false;
                }, 3000);
            }
        });
    },
    beforeUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    },
    methods: {
        /**
         * The user had the server connection (prolly internet as well) lost
         * for a while. So let's make sure that now they are back, they still
         * are in the correct place in the flow.
         *
         * Basically proactively trying to help the user.
         */
        checkIfUserShouldBeRedirectedNow() {
            // console.log('this.clientType: ', this.clientType);

            if (this.clientType === this.$CONSTANTS.CLIENT_TYPES.PLAYER) {
                this.redirectPlayerIfNeeded();
            }
        },
        ammendCrashReportNoSocketConnection() {
            this.crs.ammendCrashReport(
                CrashReportTypes.SOCKET_NOT_CONNECTING,
                'reconnected after ' + this.reconnectAttempts + ' attempts',
            );
        },
        showHowToFixModal() {
            this.showHowToFix = true;
        },
        hideHowToFixModal() {
            this.showHowToFix = false;
        },
    },
};
</script>

<style scoped lang="scss">
.connection {
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    min-height: 75px;
    padding: 0.5rem;

    &--lost {
        background-color: #e5e500;
        color: #790404;
        display: flex;
        span {
            padding-right: 0.5rem;
        }
    }

    &--restored {
        background-color: #54cd29;
        color: #fff;
        display: flex;
    }
}
.how-to-fix-modal-content {
    color: #000;
    padding: 2rem;
    h2,
    h3 {
        color: #000;
    }
    a {
        text-decoration: underline;
    }
    ol li {
        margin-top: 0.15rem;
    }
}
</style>
