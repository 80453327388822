import { i18n } from '@/lang/translator';
import { isNumeric, getLocale, jclone } from '@/core/helpers/utils';
import { isEqual } from 'lodash';
import gameTypes from '@/core/static-json/gameTypes.json';

/**
 * @constant
 * @type {Record<string, string>}
 */
export const ANSWER_INPUT_TYPE = {
    NONE: 'none',
    BOXED: 'boxed',
    DECIMAL_FRACTION: 'decimalFraction',
    IMPROPER_FRACTION: 'improperFraction',
    METRIC: 'metric',
    MIXED: 'mixed',
    MONEY: 'money',
    ORDER: 'order',
    REGULAR: 'regular', // + is default
    REMAINDER: 'remainder',
    REVERSE_FRACTION: 'reverseFraction',
    SELECT: 'select',
    SELECT_OPTION: 'selectOption',
    TIME: 'time',
    TIME_CONVERSION: 'timeConversion',
    TWO_BOX: 'twoBox',
    VERTICAL_EQUATATION: 'verticalEquatation', // +
    YES_OR_NO: 'yesOrNo', // +
};

/**
 * @constant
 * @type {Record<string, string>}
 */
export const KEYBOARD_TYPE = {
    NUMERIC: 'numeric',
    COMPARING: 'comparing',
    ENTER: 'enter',
};

/**
 * Base class that handles Game Topic related work.
 *
 * Used for all Topic domain related needs,
 * from generating questions to how should the question be displayed.
 *
 * @class TopicsBaseClass
 */
export default class TopicsBaseClass {
    /**
     *  Topic constructor
     *
     *  @param {Object} skill - math topic skill details
     */
    constructor(skill) {
        this._skill = skill;
        // console.log('CTOR', this, skill);
    }

    numberGeneratorName(convertSpecialChars = true) {
        return this.constructor.getNumberGeneratorName(
            this._skill,
            convertSpecialChars,
        );
    }

    topicName() {
        return this.constructor.getGameTopicTypeName();
    }

    getIcon() {
        return (
            gameTypes.find((game) => game.type === this._skill.type)?.icon || ''
        );
    }

    skill(next) {
        if (typeof next !== 'undefined') this._skill = next;
        return this._skill;
    }

    isType(type) {
        return this._skill.type === type;
    }

    isSkill(skill) {
        return (
            this.isType(skill.type) &&
            isEqual(skill.numberGenerator, this._skill.numberGenerator)
        );
    }

    generateQuestions(amount, noRepeats = false) {
        const list = [];

        if (!this._skill || !this._skill.numberGenerator) {
            return list;
        }

        while (list.length < amount) {
            const question = this.constructor.generateQuestion(
                this._skill.numberGenerator,
            );

            if (noRepeats) {
                // all questions should be different
                if (!list.some((q) => isEqual(q, question))) {
                    list.push(question);
                }
            } else {
                // question can not be same as previous generated only
                // but can be repeated
                if (!isEqual(question, list[list.length - 1])) {
                    list.push(question);
                }
            }
        }

        return list;
    }

    formatQuestionO(question, calledIn = 'inGame') {
        return this.constructor.formatQuestion(question, this._skill, calledIn);
    }

    formatQuestionChecked(question, calledIn = 'inGame') {
        return this.constructor.formatQuestionWithCheck(
            question,
            this._skill,
            calledIn,
        );
    }

    boxedQuestionFormat(question) {
        return this.constructor.formatBoxedQuestion(question, this._skill);
    }

    displayAnswer(questionBody, calledIn = '') {
        // In TYPE_FRACTIONS in some SPG cases (at least) the playerAnswer
        // is under questionBody.data.
        // In TYPE_SUBTRACTION topic fractions, the playerAnswer
        // us under questionBody.data.answer
        const playerAnswer =
            questionBody.playerAnswer ??
            questionBody.answer ??
            questionBody.data.playerAnswer ??
            questionBody.data.answer;

        return this.constructor.generatePlayerAnswerHtml(
            playerAnswer,
            this._skill.numberGenerator || this._skill,
            questionBody,
            calledIn,
        );
    }

    displayCorrectAnswer(questionBody, calledIn = 'inGame') {
        return this.constructor
            .generateCorrectAnswerHtml(questionBody, this._skill, calledIn)
            .trim();
    }

    checkAnswer(question, answer) {
        // todo: use comma according to locale
        const currentAnswer =
            typeof answer === 'string' ? answer.replace(/,/g, '.') : answer;

        return this.constructor.isAnswerCorrect(
            question,
            currentAnswer,
            this._skill.numberGenerator,
        );
    }

    answerInputType() {
        return this.constructor.getAnswerInputType(this._skill.numberGenerator);
    }

    keyboardInputType() {
        return this.constructor.getKeyboardType(this._skill.numberGenerator);
    }

    keyboardWithArrows() {
        return this.constructor.showArrowButtons(this._skill.numberGenerator);
    }

    onKeyboard(action, answer, activeInput) {
        return this.constructor.keyboardAnswerInput(
            answer,
            action,
            this._skill.numberGenerator,
            activeInput,
        );
    }

    resetAnswer(forQuestion = null) {
        return this.constructor.resetPlayerAnswer(
            this._skill.numberGenerator,
            forQuestion,
        );
    }

    viewClasses() {
        return this.constructor.questionViewClasses(this._skill);
    }

    viewIsInline() {
        return this.constructor.questionViewIsInline(this._skill);
    }

    $t(...args) {
        return i18n.tc(...args);
    }

    /**
     *  Topic code
     */
    static code = 'TOPIC_BASE';

    /**
     * Topic icon.
     *
     * Used when formatting question.
     * @type {string}
     */
    static icon;

    /**
     * Translation key for the game type name.
     *
     * i18n can't be used in a static variable,
     * so we only store the translation key.
     *
     * @type {string}
     */
    static gameTypeNameTranslationKey;

    /**
     * How many example questions should be generated for this topic.
     *
     * @type {number}
     */
    static sampleQuestionCount = 3;

    /**
     * Used primarily in: GameTypeNames.js
     *
     * @returns {string}
     */
    static getGameTopicTypeName() {
        return this.t(this.gameTypeNameTranslationKey);
    }

    /**
     * Used primarily in: GameTypeNames.js
     *
     * @param {Object} skill
     * @param {boolean} convertSpecialChars
     *   Conversion needs to show exponation for example.
     * @returns {string}
     */
    static getNumberGeneratorName(skill, convertSpecialChars = true) {
        const { numberGenerator } = skill;
        return numberGenerator.nr1 + '...' + numberGenerator.nr2;
    }

    /**
     * Used to generate a question for this topic.
     *
     * @param numberGenerator
     * @returns {object}
     */
    static generateQuestion(numberGenerator) {
        // Override this method to generate Topic specific questions.
        return null;
    }

    /**
     * Used to generate a question for other topics from topic class.
     * Overriden by GameTopicFactory into factory wrapper
     *
     * @param {string} topic - game topic from factory
     * @param numberGenerator - number generator
     * @returns {object | null} - generated question or null if game topic not found
     */
    static generateTopicQuestion(topic, numberGenerator) {
        // Would be overriden by GameTopicFactory
    }

    // static getTopicIcon(topic) {
    //     // Would be overriden by GameTopicFactory
    // }

    static checkIfQuestionIsBroken(
        formattedQuestion,
        attemptCounter = 1,
        originQuestion,
        skill,
    ) {
        const brokenIndicationStrings = ['undefined', 'null', 'NaN'];

        if (
            brokenIndicationStrings.some((faultyString) =>
                formattedQuestion.includes(faultyString),
            )
        ) {
            if (attemptCounter === 1) {
                // No need to spam an extra crash report for the same crash,
                // as it's still broken, so only sending for 1st attempt.
                // this.crs.startSession(CrashReportTypes.BROKEN_QUESTION);

                const gameType = skill.type;

                const numberGenerator = skill.numberGenerator;

                console.log('Broken question', formattedQuestion);

                // console.log('Current route: ', this.$route.name);

                console.log('skill: ', skill);

                console.log('question data: ', originQuestion);

                console.trace('stack trace');

                // this.crs.sendCrashReport(CrashReportTypes.BROKEN_QUESTION);
                // this.crs.endSession(CrashReportTypes.BROKEN_QUESTION);
            }

            return true;
        }

        return false;
    }

    static formatQuestionWithCheck(question, skill, calledIn = 'inGame') {
        if (!question) {
            return '';
        }
        // to be sure that question will not mutate during rendering
        const clonedQuestion = jclone(question);

        let formattedQuestion = this.formatQuestion(
            clonedQuestion,
            skill,
            calledIn,
        );

        if (
            this.checkIfQuestionIsBroken(formattedQuestion, 1, question, skill)
        ) {
            // Try once more, maybe it's a fluke.
            // I did not do recursion, as most likely if it's broken once,
            // it'll be broken still.
            formattedQuestion = this.formatQuestion(
                clonedQuestion,
                skill,
                calledIn,
            );
            if (
                this.checkIfQuestionIsBroken(
                    formattedQuestion,
                    2,
                    question,
                    skill,
                )
            ) {
                console.error(
                    'Format Question Broken!',
                    question,
                    skill,
                    formattedQuestion,
                );
            }
        }

        return formattedQuestion;
    }

    /**
     * Formats question data to HTML string, so it can be rendered.
     *
     * @param questionData
     * @param calledIn
     * @returns {string}
     */
    static formatQuestion(questionData, skill, calledIn = 'inGame') {
        let nr1;
        let nr2;

        if (questionData.number1 || questionData.number1 === 0) {
            nr1 = questionData.number1;
            nr2 = questionData.number2;
        } else {
            nr1 = questionData.nr1;
            nr2 = questionData.nr2;
        }

        nr1 = parseFloat(nr1).toLocaleString(this.locale());

        nr2 = parseFloat(nr2).toLocaleString(this.locale());

        // Adds brackets to the questions like this:
        // 5 - -5 so it'd be 5 - (-5).
        if (nr2 < 0) {
            nr2 = `(${nr2})`;
        }

        return `${nr1} ${this.icon} ${nr2}`;
    }

    /**
     * Formats boxed question HTML string, for skills with boxed answer input
     *
     * @param {Object} question
     * @returns {Object} skill
     */
    static formatBoxedQuestion(question, skill) {
        return null;
    }

    /**
     *  Check is answer to question is correct
     *
     *  Should be overridden by extended classes
     *
     *  @param {Object} question  - original question
     *  @param {Object} answer    - player's answer
     *  @param {String|undefined} numberGenerator - game skill
     *  @returns {boolean}
     */
    static isAnswerCorrect(question, answer, numberGenerator = undefined) {
        return question?.answer === Number(answer);
    }

    /**
     *  Check is answer to question is empty.
     *
     *  May be overridden by extended classes. Not static to allow usage in
     *  the MixMultipleTopic.
     *
     *  @param {Object} answer - player's answer
     *  @param {object|undefined} numberGenerator - game skill
     *  @param {object|undefined} question - current question
     *  @returns {boolean}
     */
    isAnswerEmpty(answer, numberGenerator = undefined, question = undefined) {
        // todo: cover complex cases in topic's overrides

        if (typeof answer === 'string') {
            // todo: use comma according to locale
            const currentAnswer = answer.replace(/,/g, '.');
            const comparingAndOrderingTopicAnswer = (answer) => {
                return answer === '<' || answer === '>' || answer === '=';
            };
            if (comparingAndOrderingTopicAnswer(currentAnswer)) {
                return false;
            }

            const yesAndNoAnwser = (answer) => {
                return answer === 'Yes' || answer === 'No';
            };
            if (yesAndNoAnwser(currentAnswer)) {
                return false;
            }

            return currentAnswer === '' || isNaN(Number(currentAnswer));
        } else {
            return false;
        }
    }

    /**
     *  Check is answer to question is equal
     *
     *  May be overridden by extended classes. Not static to allow usage in
     *  the MixMultipleTopic.
     *
     *  @param {Object} answerA - player's answer
     *  @param {Object} answerB - player's answer
     *  @param {Object|undefined} numberGenerator - game skill
     *  @param {Object|undefined} question - current question
     *  @returns {boolean}
     */
    isAnswerEqual(
        answerA,
        answerB,
        numberGenerator = undefined,
        question = undefined,
    ) {
        return (
            // Number(null) is 0, so check it separately
            answerA !== null &&
            !isNaN(Number(answerA)) &&
            Number(answerA) === Number(answerB)
        ); // todo: cover complex cases in topics
    }

    /**
     *  answer data for formatPlayerAnswerData method
     *
     *  @param {Object} question
     *  @param {Object} answer
     *  @param {Object} skill
     */
    static answerData(question, answer, skill) {
        return question.data || {};
    }

    /**
     * Format player answer data before it is sent to the BE.
     *
     * To override it, call parent(super) first
     * and then add topic specific changes.
     *
     *
     * @param {Object} question
     * @param {Object} playerAnswer
     * @param {Object} skill
     * @param {number} timer
     * @param {number} timerPreviousAnswer
     * @returns {{
     *  playerAnswer: Object, isCorrectAnswer: boolean, questionBody: Object
     * }}
     */
    static formatPlayerAnswerData(
        question,
        playerAnswer,
        skill,
        timer,
        timerPreviousAnswer,
    ) {
        const {
            number1,
            number2,
            number3,
            number4,
            number5,
            metric,
            shape,
            answer,
            baseNumber,
        } = question;

        const formattedPlayerAnswer = Number(playerAnswer);

        const isCorrectAnswer = this.isAnswerCorrect(
            question,
            playerAnswer,
            skill.numberGenerator,
        );

        const data = this.answerData(question, playerAnswer, skill);

        // Remember that 0 is treated as false the || operator applies.
        const questionBody = {
            type: skill.type,
            nr1: isNumeric(number1)
                ? number1
                : baseNumber
                  ? baseNumber
                  : question.number
                    ? question.number
                    : question.nr1,
            nr2: isNumeric(number2)
                ? number2
                : isNumeric(question.nr2)
                  ? question.nr2
                  : null,
            nr3: isNumeric(number3)
                ? number3
                : isNumeric(question.nr3)
                  ? question.nr3
                  : null,
            nr4: isNumeric(number4)
                ? number4
                : isNumeric(question.nr4)
                  ? question.nr4
                  : null,
            nr5: isNumeric(number5)
                ? number5
                : isNumeric(question.nr5)
                  ? question.nr5
                  : null,
            metric,
            shape,
            answer,
            playerAnswer: formattedPlayerAnswer,
            correct: isCorrectAnswer,
            data,
            time: timer - timerPreviousAnswer,
        };

        return {
            playerAnswer: formattedPlayerAnswer,
            isCorrectAnswer,
            questionBody,
        };
    }

    /**
     * Display the player answer (mostly in the report).
     *
     * Allows to make the answer rendering
     * topic specific (for example for fractions).
     *
     * @param playerAnswer
     * @param numberGenerator
     * @param question
     * @param calledIn
     * @returns {string}
     */
    static generatePlayerAnswerHtml(
        playerAnswer,
        numberGenerator,
        question,
        calledIn = 'inGame',
    ) {
        return ` =&nbsp;<span class="wrong-answer">${playerAnswer}</span>`;
    }

    /**
     * Display the correct answer for the question
     *
     * @param {Object}  question
     * @param {Object}  numberGenerator
     * @param {string}  calledIn
     * @returns {string}
     */
    static generateCorrectAnswerHtml(
        question,
        numberGenerator,
        calledIn = 'inGame',
    ) {
        return ` =&nbsp;<span class="correct-answer">${question.answer}</span>`;
    }

    /**
     * @param {Object} numberGenerator
     * @returns {string}
     */
    static getAnswerInputType(numberGenerator) {
        return ANSWER_INPUT_TYPE.REGULAR;
    }

    /**
     * @param {Object} numberGenerator
     * @returns {string}
     */
    static getKeyboardType(numberGenerator) {
        return KEYBOARD_TYPE.NUMERIC;
    }

    /**
     * @param {Object} numberGenerator
     * @returns {boolean}
     */
    static showArrowButtons(numberGenerator) {
        return false;
    }

    /**
     * @param {Object} numberGenerator
     * @returns {string}
     */
    static resetPlayerAnswer(numberGenerator, question = null) {
        return '';
    }

    /**
     *  Modifies answer according to keyboard action
     *
     *  @param {string | Object} answer - current answer
     *  @param {string} input - keyboard action
     *  @param {Object} numberGenerator - game number generator
     *
     *  @return {string | Object} - modified answer
     */
    static keyboardAnswerInput(
        answer,
        input,
        numberGenerator = undefined,
        activeInput = undefined,
    ) {
        let src = answer;

        let obj = false;

        /*
        console.log(
            'TBC::kai1',
            answer,
            input,
            numberGenerator,
            activeInput,
            src,
        );
        */
        if (
            typeof answer === 'object' &&
            activeInput &&
            Object.keys(answer || {}).includes(activeInput)
        ) {
            src = answer[activeInput];

            obj = true;
        }

        if (input === 'delete') {
            if (!src || src === '' || !src.length) {
                src = '';
            } else {
                src = src.slice(0, -1);
            }
        } else if (input === 'submit') {
            // Do nothing.
        } else {
            src = `${typeof src === 'object' ? '' : src || ''}${input}`;
        }
        /*
        console.log(
            'TBC::kai1',
            answer,
            input,
            numberGenerator,
            activeInput,
            src,
            obj,
        );
        */
        if (!obj) {
            return src;
        }

        return { ...answer, [activeInput]: src };
    }

    /**
     *  Check is skill question view displayed inline
     *
     *  @param {Object} skill - game skill
     *  @return {boolean}
     */
    static questionViewIsInline(skill) {
        return true;
    }

    /**
     *  Collect array of question view html classes
     *
     *  @param {Object} skill - game skill
     *  @return {boolean}
     */
    static questionViewClasses(skill) {
        const list = [];

        list.push('c-questions-wrap__question');

        list.push(`c-questions-wrap__question--${skill.type}`);

        if (skill.numberGenerator.topic) {
            list.push(
                `c-questions-wrap__question--${skill.numberGenerator?.topic}`,
            );
        }

        if (skill.numberGenerator.subtopic) {
            list.push(
                `c-questions-wrap__question--${skill.numberGenerator?.subtopic}`,
            );
        }

        /*
        if (skill === 'TYPE_ROUNDING') list.push('text-question');
        if (
            skill === 'TYPE_NUMBER_LINE' &&
            skill.numberGenerator.numberLineBase === 'fraction'
        )
            list.push('thinner-font');
        */
        if (this.questionViewIsInline(skill)) {
            list.push('inline-flex');
        }

        return list;
    }

    /**
     *  current locale helper
     */
    static locale() {
        return getLocale();
    }

    /**
     *  translate helper
     */
    static t(...args) {
        return i18n.tc(...args);
    }

    /**
     *  Number formatter helper
     */
    static formatNumber(num, locale = null) {
        if (!num && num !== 0) {
            return;
        }

        if (!locale) {
            locale = this.locale();
        }

        const decimals = num.toString().split('.')[1];

        const decimalPlaces = (decimals && decimals.length) || 0;

        return Number(num)
            .toLocaleString(locale, {
                minimumFractionDigits: decimalPlaces,
            })
            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
}
