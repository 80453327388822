/* global mixpanel */

export const SOLO_TRACK_EVENTS = {
    STG_STARTED_PLAYING: 'startedPlayingSTG',
    STUDENT_RAISE_SOLO_TRACK: 'studentRaiseSoloTrack',
    TEACHER_CHANGE_SOLO_TRACK: 'teacherChangeSoloTrack',
    TEACHER_CHANGE_SOLO_TRACK_BULK: 'teacherChangeSoloTrackBulk',
    VIEWED_STUDENT_SOLO_TRACK_PAGE: 'viewedStudentSoloTrackPage',
    VIEWED_TEACHER_SOLO_TRACK_PAGE: 'viewedTeacherSoloTrackPage',
    TEACHER_OPEN_REAL_TIME_VIEW: 'teacherOpenRealTimeView',
    TEACHER_CLOSED_REAL_TIME_VIEW: 'teacherClosedRealTimeView',
    TEACHER_CHOOSE_NEW_HOME: 'teacherChooseNewHome',
    TEACHER_CLICK_HOW_IT_WORKS_ST: 'teacherClickHowItWorksST',
    TEACHER_CLICK_LETS_GO_ST: 'teacherClickLetsGoST',
    TEACHER_CLICK_START_PLAYING_ST: 'teacherClickStartPlayingST',
    TEACHER_CLICK_SEE_MISTAKES_ST: 'teacherClickSeeMistakesST',
    TEACHER_CLICK_SAMPLE_SEE_MISTAKES_ST: 'teacherClickSampleSeeMistakesST',
    TEACHER_CLICK_ASSIGN_PATH_ST: 'teacherClickAssignPathST',
    TEACHER_CLICK_ASSIGN_SKILL_ST: 'teacherClickAssignSkillST',
    TEACHER_REQUESTED_TOPIC_ST: 'requestedTopicInST',
    TEACHER_REQUESTED_TOPIC_ANSWER_ST: 'requestedTopicAnswerInST',
    VIEWED_TEACHER_GET_STARTED_ST: 'teacherViewGetStartedST',
    VIEWED_TEACHER_IMPORT_CLASSES_ST: 'teacherViewImportClassesST',
    VIEWED_TEACHER_ASSIGN_PATH_ST: 'teacherViewAssignPathST',
    VIEWED_TEACHER_REAL_REPORT_ST: 'teacherViewRealReportST',
    TEACHER_OPEN_ST_DEMO_MODAL: 'teacherOpenSTDemoModal',
    TEACHER_CLOSE_ST_DEMO_MODAL: 'teacherCloseSTDemoModal',
    VIEWED_TEACHER_SLIDE_ST: 'teacherViewSlideST',
    CLOSED_TEACHER_CLOSE_SLIDES: 'teacherCloseSlideST',
    STUDENT_VIEWED_FTUE: 'studentViewedFTUE',
    STUDENT_CLICK_EDIT_NAME_FTUE: 'studentClickEditNameFTUE',
    STUDENT_VIEWED_AVATAR_FTUE: 'studentViewedAvatarFTUE',
    STUDENT_CHOOSE_AVATAR_FTUE: 'studentChooseAvatarFTUE',
    STUDENT_CONFIRM_AVATAR_FTUE: 'studentConfirmAvatarFTUE',
    DAILY_REWARD_VIEWED: 'dailyRewardViewed',
    DAILY_REWARD_CLAIMED_AT_HOME: 'dailyRewardClaimedAtHome',
    DAILY_REWARD_CLAIMED_AT_SCHOOL: 'dailyRewardClaimedAtSchool',
    DAILY_REWARD_BACK_CLICKED: 'dailyRewardBackClicked',
    STUDENT_VIEWED_MY_PARK: 'studentViewedMyPark',
    STUDENT_VIEWED_CLASSMATE_PARK: 'studentViewedClassmatePark',
    STUDENT_CLICKED_VISIT_THEIR_PARK: 'studentClickedVisitTheirPark',
    STUDENT_ADDED_DECORATION_MY_PARK: 'studentAddedDecorationMyPark',
    STUDENT_REACT_PARK: 'studentReactPark',
    STUDENT_OPENS_PARK_FOR_FREE: 'studentOpensParkForFree',
    STUDENT_OPENS_PARK_FOR_MBUCKS: 'studentOpensParkForMbucks',
    STUDENT_OPEN_GET_EMOTES_MODAL: 'studentOpenGetEmotesModal',
    ST_GAME_FINISHED: 'stGameFinished',
};

export const SOLO_TRACK_METHODS = {
    studentOpensParkForFree({ isSchoolTime }) {
        mixpanel.track('Opens park', {
            'Is School Time': isSchoolTime,
        });
    },
    studentOpensParkForMbucks({ openingPrice, hasEnoughMbucks }) {
        mixpanel.track('Buys park opening', {
            'Opening price': openingPrice,
            'Has enough budget': hasEnoughMbucks,
        });
    },
    studentReactPark({ emote, ownerPlayerName }) {
        mixpanel.track('Student reacts in My Park', {
            'Park owner name': ownerPlayerName,
            Emote: emote,
        });
    },
    studentViewedMyPark({
        numberOfDecorations,
        availableDecorations,
        parkHasGate,
        isParkOpen,
    }) {
        mixpanel.track('Viewed My Park', {
            'Number of decorations': numberOfDecorations,
            'Available decorations': availableDecorations,
            'Park is time-limited': parkHasGate,
            'Park is now open': isParkOpen,
        });
    },
    studentViewedClassmatePark({ numberOfDecorations }) {
        mixpanel.track('Viewed Classmate Park', {
            'Number of decorations': numberOfDecorations,
        });
    },
    studentClickedVisitTheirPark() {
        mixpanel.track('Clicked Visit Their Park');
    },
    studentOpenGetEmotesModal() {
        mixpanel.track('Open Get More Emotes Modal');
    },
    studentAddedDecorationMyPark({ name }) {
        mixpanel.track('Added new decoration to My Park', {
            'Decoration Name': name,
        });
    },
    startedPlayingSTG({ gameCode, taskType, taskScale, creatorRole }) {
        mixpanel.track('Started STG', {
            Gamecode: gameCode,
            'Task type': taskType,
            'Task scale': taskScale,
            'Game creator role': creatorRole,
        });
    },
    teacherViewGetStartedST() {
        mixpanel.track('Viewed Get Started step in ST');
    },
    teacherViewImportClassesST() {
        mixpanel.track('Viewed Import Classes step in ST');
    },
    teacherViewAssignPathST() {
        mixpanel.track('Viewed Assign Path step in ST');
    },
    teacherViewRealReportST() {
        mixpanel.track('Viewed Real Report in ST');
    },
    teacherOpenSTDemoModal() {
        mixpanel.track('Opened ST Demo modal');
    },
    teacherCloseSTDemoModal() {
        mixpanel.track('Closed ST Demo modal');
    },
    teacherViewSlideST({ slideIndex }) {
        mixpanel.track(`Viewed Slide ${slideIndex} in ST slides`);
    },
    teacherCloseSlideST({ slideIndex }) {
        mixpanel.track(`Clicked close ST slides`, {
            'Active slide': slideIndex,
        });
    },
    viewedStudentSoloTrackPage() {
        mixpanel.track('Viewed student solo track page');
    },
    viewedTeacherSoloTrackPage({
        classCode,
        className,
        solvedAnyProblems,
        threePlayersHaveSolvedProblems,
        onlineStudentsCount,
    }) {
        mixpanel.track('Viewed teacher solo track page', {
            'Class code': classCode,
            'Class name': className,
            'Solved any problems': solvedAnyProblems,
            '3 Players solved any problems': threePlayersHaveSolvedProblems,
            'Online students count': onlineStudentsCount,
        });
    },
    teacherOpenRealTimeView() {
        mixpanel.track('Viewed teacher open RTV');
    },
    teacherClosedRealTimeView() {
        mixpanel.track('Viewed teacher close RTV');
    },
    teacherChooseNewHome(choice) {
        mixpanel.track('Chose in Home View', { Choice: choice });
    },
    teacherClickHowItWorksST(ftue) {
        mixpanel.track('Clicked See how it works in ST', { FTUE: ftue });
    },
    teacherClickStartPlayingST() {
        mixpanel.track('Clicked Start playing in ST');
    },
    teacherClickAssignPathST() {
        mixpanel.track('Clicked Assign Path in ST');
    },
    teacherClickAssignSkillST() {
        mixpanel.track('Clicked Assign Skill in ST');
    },
    teacherClickLetsGoST() {
        mixpanel.track('Clicked Lets go in ST ');
    },
    teacherClickSeeMistakesST() {
        mixpanel.track('Viewed See mistakes in ST');
    },
    teacherClickSampleSeeMistakesST() {
        mixpanel.track('Viewed See mistakes of Sample Report in ST');
    },
    teacherChangeSoloTrack({
        student,
        newTopic,
        oldTopic,
        classCode,
        className,
    }) {
        mixpanel.track('Teacher change solo track', {
            'Class code': classCode,
            'Class name': className,
            'Student name': student.userInfo.name,
            'New track': newTopic,
            'Old track': oldTopic,
        });
    },
    teacherChangeSoloTrackBulk({
        studentIds,
        newTopic,
        classCode,
        className,
        grade,
    }) {
        mixpanel.track('Teacher change solo track Bulk', {
            'Class code': classCode,
            'Class name': className,
            'Student ids': studentIds,
            'New track': newTopic,
            Grade: grade,
        });
    },
    studentRaiseSoloTrack({ classCode, studentName, newTopic, oldTopic }) {
        mixpanel.track('Student raise solo track', {
            'Class code': classCode,
            'Student name': studentName,
            'New track': newTopic,
            'Old track': oldTopic,
        });
    },
    requestedTopicInST({ topic, grade }) {
        mixpanel.track('Requested a topic in ST', {
            Topic: topic,
            Grade: grade,
        });
    },
    requestedTopicAnswerInST({ topic, answer, grade }) {
        mixpanel.track('Requested topic answer in ST', {
            Topic: topic,
            Answer: answer,
            Grade: grade,
        });
    },

    studentViewedFTUE() {
        mixpanel.track('Viewed Student FTUE');
    },

    studentClickEditNameFTUE() {
        mixpanel.track('Clicked edit player name in STUDENT FTUE');
    },

    studentViewedAvatarFTUE() {
        mixpanel.track('Viewed choose avatar in Student FTUE');
    },

    studentChooseAvatarFTUE() {
        mixpanel.track('Choose new avatar in Student FTUE');
    },

    studentConfirmAvatarFTUE() {
        mixpanel.track('Confirmed avatar in Student FTUE');
    },
    dailyRewardViewed({ schoolToClaim, homeToClaim }) {
        mixpanel.track('Viewed daily rewards', {
            'School rewards to claim': schoolToClaim,
            'Home rewards to claim': homeToClaim,
        });
    },
    dailyRewardClaimedAtHome({ dayClaimed }) {
        mixpanel.track('Claimed At Home daily reward', {
            'Day number': dayClaimed,
        });
    },
    dailyRewardClaimedAtSchool({ dayClaimed }) {
        mixpanel.track('Claimed At School daily reward', {
            'Day number': dayClaimed,
        });
    },
    dailyRewardBackClicked() {
        mixpanel.track('Clicked Back in daily rewards');
    },
    stGameFinished({ problemsSolved }) {
        mixpanel.track('ST game finished', {
            'Problems solved': problemsSolved,
        });
    },
};
