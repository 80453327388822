export const ParentRoutes = [
    {
        path: '/parents/account-recovery',
        name: 'parent.account-recovery',
        component: () => import('./pages/AccountRecovery/AccountRecovery.vue'),
        meta: {
            authenticate: false,
        },
    },
];
