import store from '@/store';
import {
    randomIntFromRange,
    randomArrayElement,
    jclone,
} from '@/core/helpers/utils';
import { app } from '@/main.js';

export class OneVOneBot {
    constructor(playerLook, tpm, stats) {
        this._store = store;
        this._playerLook = playerLook;
        this._stats = stats || {
            playedTime: 0,
            started: new Date(),
            round: 1,
            correctAnswers: 0,
            wrongAnswers: 0,
            answers: [],
        };
        this._tpm = tpm;
        this._perDebuffSlowdown = this._tpm * 0.1; // 10% of tpm
        this._gameDuration = 60;
        this._unsubscribe = null;
        this._play = false;
        app.config.globalProperties.$emitter.on('force-use-boost', (boost) =>
            this.useBoost(boost),
        );
    }

    async resumeBot() {
        if (this._store.state.v2.onevone.pickedIfBoosters === 'yes') {
            this.tryUseBoost();
        }
        this.startBotGamePlay();
    }

    startBotGamePlay() {
        this._play = true;
        this.answerQuestionLoop();
    }

    async tryUseBoost() {
        const timeTillUseBoost = randomIntFromRange(5000, 10000);
        console.debug(
            `[1v1] bot will use boost in ${timeTillUseBoost / 1000}s`,
        );
        await this.waitMs(timeTillUseBoost);
        this.useBoost();
    }

    async useBoost(boost) {
        const boosters = jclone(store.state.v2.onevone.boosterChoices);
        const boosterPick = randomArrayElement(boosters);
        const booster = boost ? jclone(boost) : jclone(boosterPick);
        console.debug(
            `[1v1] bot tries use boost ${booster.id} ${this._playerLook.playerName}`,
        );
        await store.commit('v2/onevone/toggleBooster', {
            booster,
            playerName: this._playerLook.playerName,
        });
        console.debug(
            `[1v1] bot activates boost ${booster.id} ${this._playerLook.playerName}`,
        );
        store.dispatch('v2/onevone/activateBooster', {
            booster,
            playerName: this._playerLook.playerName,
        });
    }

    stopBotGamePlay() {
        this._play = false;
        console.debug(`[1v1] bot stopped playing`);
        if (Math.random() < 0.8) {
            const randomWait = randomIntFromRange(800, 1300);
            setTimeout(() => {
                store.dispatch('v2/onevone/react', {
                    reaction: randomArrayElement([
                        'great-job',
                        'i-can-do-better',
                        'thumbs-up',
                    ]),
                    playerName: this._playerLook.playerName,
                });
            }, randomWait);
        }
    }

    getActiveDebuffs() {
        const affectsBot = store.getters['v2/onevone/affectOpponent'];
        if (affectsBot.find((b) => b.id === 'shield')) return 0;
        return (
            affectsBot.filter((b) => ['blackhole', 'tornado'].includes(b.id))
                ?.length || 0
        );
    }

    async answerQuestionLoop() {
        const activeDebuffs = this.getActiveDebuffs();
        const debuffTimeSeconds = activeDebuffs * this._perDebuffSlowdown;
        const secondsToWait =
            this._gameDuration / this._tpm + debuffTimeSeconds;
        const msToWait = Math.round(secondsToWait * 1000);
        console.debug(`[1v1] bot waits ${msToWait}ms`);
        const timeOut = randomIntFromRange(msToWait - 500, msToWait + 500);
        await this.waitMs(timeOut);
        if (!this._play) return;
        this._stats.correctAnswers += 1;
        this.answerQuestionLoop();
    }

    waitMs(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    close() {
        if (this._unsubscribe) {
            this._unsubscribe();
            this._unsubscribe = null;
        }
    }
}
