<template>
    <teleport v-if="showCustomerSupportModal" to="#app">
        <Loading v-show="isLoading" display="absolute" />
        <SuperAwesomeModal
            :x-close="true"
            :x-back="true"
            :close-on-click-outside="!isLoading"
            @clickedBack="clickedBack"
            @modalClosed="hideModal"
        >
            <div class="customer-support-modal">
                <TicketCreated v-if="ticketCreated" @close="hideModal" />
                <TechIssueForm
                    v-else-if="currentlyShow.techIssueForm"
                    @ticketDataSubmit="tryTicketCreation"
                />
                <SuggestFeatureForm
                    v-else-if="currentlyShow.suggestFeatureForm"
                    @ticketDataSubmit="tryTicketCreation"
                />
                <div v-else class="first-step">
                    <h1>I wish to:</h1>
                    <div class="options">
                        <MathButton @click="choseStep('techIssue')">
                            Report a technical issue
                        </MathButton>
                        <MathButton @click="howTo()">FAQ</MathButton>
                        <MathButton @click="choseStep('suggestFeature')">
                            Suggest a feature
                        </MathButton>
                    </div>
                    <div class="divider" />
                    <div>
                        <p>Something else?</p>
                        <span>Contact us via hello@99math.com</span>
                    </div>
                </div>
            </div>
        </SuperAwesomeModal>
    </teleport>
</template>

<script>
import SuperAwesomeModal from '@/core/ui/Modal/SuperAwesomeModal.vue';
import MathButton from '@/core/ui/_legacy/MathComponents/MathButton.vue';
import TechIssueForm from '@/core/modals/CustomerSupportModal/components/TechIssueForm.vue';
import SuggestFeatureForm from '@/core/modals/CustomerSupportModal/components/SuggestFeatureForm.vue';
import SecureApi from '@/flows/Authentication/services/SecureApi';
import { mapGetters } from 'vuex';
import { BrowserDetect } from '@/core/mixins/BrowserDetect';
import Loading from '@/core/ui/_legacy/MathComponents/MathLoadingSpinner.vue';
import TicketCreated from '@/core/modals/CustomerSupportModal/components/TicketCreated.vue';
import {
    EVENTS,
    TrackingService,
} from '@/core/services/TrackingService/TrackingService';
import { jclone } from '@/core/helpers/utils.js';
import io from 'socket.io-client';
const MATH99_REGIONS = (import.meta.env.VITE_MATH99_REGIONS || '').split(' ');

export default {
    components: {
        MathButton,
        SuperAwesomeModal,
        TechIssueForm,
        SuggestFeatureForm,
        Loading,
        TicketCreated,
    },
    mixins: [BrowserDetect],
    data() {
        return {
            showCustomerSupportModal: false,
            ticketType: null,
            isLoading: false,
            ticketCreated: false,
            ticketCreationError: false,
            websocketConnection: null,
            services: {},
        };
    },
    computed: {
        ...mapGetters({
            gamesPlayed: 'getGamesPlayedTotalCount',
            user: 'user',
        }),
        regions() {
            return MATH99_REGIONS;
        },
        currentlyShow() {
            if (this.ticketType === 'techIssue') {
                return {
                    techIssueForm: true,
                };
            }

            if (this.ticketType === 'suggestFeature') {
                return {
                    suggestFeatureForm: true,
                };
            }

            return {
                intro: true,
            };
        },
    },
    mounted() {
        this.$emitter.on('openCustomerSupportModal', this.showModal);
    },
    beforeUnmount() {
        this.$socket.client.removeListener('connect');
        this.$socket.client.removeListener('disconnect');
        this.$socket.client.close();
        this.$store.dispatch('v2/io/leave');
    },
    methods: {
        showModal() {
            this.showCustomerSupportModal = true;
            new TrackingService().track(EVENTS.CLICKED_SUPPORT);
        },
        hideModal() {
            this.resetData();

            this.showCustomerSupportModal = false;
        },
        clickedBack() {
            if (!this.ticketType) {
                return this.hideModal();
            }

            this.ticketType = null;
        },
        choseStep(step) {
            this.ticketType = step;
            if (this.ticketType === 'techIssue') {
                this.startHealthCheck();
            }
            new TrackingService().track(EVENTS.CLICKED_SUPPORT_TYPE, step);
        },
        startHealthCheck() {
            MATH99_REGIONS.forEach((region) => {
                // "live io manager" for microservices connections
                const ioLiveManager = io(
                    import.meta.env[`VITE_${region}_TBL_API_URL`],
                    {
                        autoConnect: false,
                        transports: ['websocket'],
                        cors: [import.meta.env[`VITE_${region}_TBL_API_URL`]],
                        // query fields JWT and CC are applied in store/modules/io.js
                        // but query object should be defined for easy later access
                        // sending JWT in query, cuz
                        // "In a browser environment, the extraHeaders option will be ignored if you only enable the WebSocket transport,
                        // since the WebSocket API in the browser does not allow providing custom headers."
                        // (https://socket.io/docs/v4/client-options/#extraheaders)
                        // but token should be accessible on allowRequest
                        query: {},
                        multiplex: false,
                        forceNew: true,
                    },
                );

                // @/store/modules/io.js manages ws connections
                // this works "in parallel" with "classic vue-socket-io way"
                // and should not break any other code working in "classic" way
                this.$store.commit('v2/io/setIo', {
                    io: ioLiveManager.io,
                    region,
                });
            });
            this.regions.forEach((region) => {
                this.services[region] = {
                    connected: false,
                    synced: false,
                    timerSync: null,
                };
            });

            this.$store.dispatch('v2/io/healthCheck', 'live');

            this.regions.forEach((region) => {
                const regionService =
                    this.$store.state.v2.io?.healthCheck[region];

                if (!regionService || !regionService.socket) return;

                let timeout = null;

                regionService.socket.on('connect', () => {
                    this.services[region].connected = true;
                });

                regionService.socket.on('disconnect', () => {
                    this.services[region].connected = false;
                });

                regionService.socket.on('timerSync', (data) => {
                    if (!this.services[region]) return;

                    this.services[region].synced = true;

                    this.services[region].timerSync = data;

                    if (timeout) clearTimeout(timeout);

                    timeout = setTimeout(() => {
                        console.log('TIMEOUT', region);

                        this.services[region].synced = false;
                    }, 5000);

                    const tmp = jclone(this.services);

                    this.services = {};

                    setTimeout(() => {
                        this.services = jclone(tmp);
                    }, 0);
                });
            });

            this.connectToWs();
        },
        connectToWs() {
            this.websocketConnection = false;

            if (this.$socket.client.connected) {
                this.websocketConnection = true;

                this.$socket.client.emit('healthCheck');

                console.log('Health socket already connected');
            } else {
                this.$socket.client.io.opts.query = { jwt: 'healthcheck' };

                this.$socket.client.on('connect', () => {
                    this.websocketConnection = true;

                    this.$socket.client.emit('healthCheck');

                    // this.$socket.client.emit('gameRoomJoin', 'testRoom');
                    console.log('Health socket connected');
                });

                this.$socket.client.open();
            }
            this.$socket.client.on('disconnect', () => {
                this.websocketConnection = false;

                console.log('Health socket disconnected');
            });
        },
        howTo() {
            new TrackingService().track(EVENTS.CLICKED_SUPPORT_TYPE, 'howTo');
            window.open('https://faq.99math.com/', '_blank');
        },
        async tryTicketCreation(ticketData) {
            this.isLoading = true;

            const browserInfo = this.getBrowserInfo();

            const osInfo = this.getOSInfo();

            const body = {
                ticketType: this.ticketType,
                clientInfo: {
                    email: ticketData.email || this.user.email,
                    firstName: this.user.firstName,
                    lastName: this.user.lastName,
                    role: this.user.role,
                    country: this.user.country,
                    gamesPlayed: this.gamesPlayed,
                    browser: `${browserInfo.BrowserName} ${browserInfo.MajorVersion} ${browserInfo.Version}`,
                    OS: `${osInfo.OS} ${osInfo.version}`,
                    chosenLanguage: this.user.locale,
                },
            };

            if (this.currentlyShow.techIssueForm) {
                body.techIssue = {
                    issueForWho: ticketData.issueForWho,
                    devicesUsed: ticketData.devicesUsed,
                    happenedBefore: ticketData.happenedBefore,
                    issueDescription: ticketData.issueDescription,
                    applicationVersion: ticketData.applicationVersion,
                    healthCheck: `${this.websocketConnection}`,
                };
            } else {
                body.suggestFeature = {
                    featureDescription: ticketData.featureDescription,
                };
            }

            try {
                const response = await SecureApi().post(
                    '/customer-support/create-ticket',
                    body,
                );

                this.isLoading = false;

                if (response.data.success) {
                    this.ticketCreated = true;

                    return;
                }

                console.error(
                    `Create ticket server error: ${JSON.stringify(
                        response.data.error,
                    )}`,
                );
            } catch (error) {
                console.error(`Create ticket request error: ${error}`);

                this.isLoading = false;
            }
            this.ticketCreationError = true;
        },
        resetData() {
            this.ticketType = null;

            this.isLoading = false;

            this.ticketCreated = false;

            this.ticketCreationError = false;
        },
    },
};
</script>

<style scoped lang="scss">
.customer-support-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #000;
    padding: 1rem;
    text-align: center;

    h1 {
        color: #000;
        margin-bottom: 2rem;
    }

    .options .math-button {
        margin: 0.75rem auto;
        min-width: 300px;
    }

    .divider {
        margin-top: 2rem;
        border-top: 1px solid #525050;
        width: 100%;
    }
}
</style>
