/* global mixpanel */

export const TEACHER_UI_EVENTS = {
    STEP_GOAL_CLICKED: 'stepGoalClicked',
    STEP_ASSIGNMENTS_CLICKED: 'stepAssignmentsClicked',
    DEMO_ENTERED: 'demoEntered',
    DEMO_EXITED: 'demoExited',
    WEEKLY_GOAL_SET: 'weeklyGoalSet',
    NAV_TO_CLASSES: 'navToClasses',
    NAV_TO_REPORTS: 'navToReports',
    NAV_TO_REFERRALS: 'navToReferrals',
    NAV_TO_ST: 'navToST',
    SWITCHED_TO_OLD_UI: 'switchedToOldUI',
    MENU_COLLAPSED: 'menuCollapsed',
    MENU_EXPANDED: 'menuExpanded',
    DISMISSED_WELCOME: 'dismissedWelcome',
    DISMISSED_GOAL_WHY: 'dismissedGoalWhy',
    CLICKED_WHO_IS_PLAYING: 'clickedWhoIsPlaying',
    CLICKED_WHO_IS_NOT_PLAYING: 'clickedWhoIsNotPlaying',
    COMPLETED_HOME_ACCESS: 'completedHomeAccess',
    CURRICULUM_INFO_ICON_CLICKED: 'curriculumInfoIconClicked',
    UNLOCKED_PREMIUM_FEATURE: 'unlockedPremiumFeature',
    SELECTED_PREMIUM_FEATURE: 'selectedPremiumFeature',
    OPEN_TO_PAID_RESOURCES: 'openToPaidResources',
    TEACHER_SEES_PROMO_BANNER: 'teacherSeesPromoBanner',
    TEACHER_CLICKS_PROMO_CTA: 'teacherClicksPromoCta',
    VIEWED_HOME_PRACTICE_PAGE: 'viewedHomePracticePage',
};

export const TEACHER_UI_METHODS = {
    stepGoalClicked({ stepStatus }) {
        mixpanel.track('Teacher clicked set a goal step', {
            'Step Status': stepStatus,
        });
    },
    stepAssignmentsClicked({ stepStatus }) {
        mixpanel.track('Teacher clicked assignments step', {
            'Step Status': stepStatus,
        });
    },
    demoEntered() {
        mixpanel.track('Teacher entered demo');
    },
    demoExited({ slide }) {
        mixpanel.track('Teacher exited demo', {
            Slide: slide,
        });
    },
    weeklyGoalSet({ newGoal, prevGoal, isCustom }) {
        mixpanel.track('Teacher set weekly goal', {
            'New Goal (minutes)': newGoal,
            'Previous Goal (minutes)': prevGoal,
            'Is Custom': isCustom,
        });
    },
    navToClasses({ origin }) {
        mixpanel.track('Teacher navigated to classes', {
            Origin: origin, // sidebar, class selector
        });
    },
    navToReports() {
        mixpanel.track('Teacher navigated to reports');
    },
    navToST() {
        mixpanel.track('Teacher navigated to Individual Practice');
    },
    navToReferrals({ way }) {
        mixpanel.track('Teacher navigated to Referral program', {
            'Navigation Way': way, // menu, banner, direct
        });
    },
    switchedToOldUI({ way }) {
        mixpanel.track('Teacher switched to old UI', {
            'Navigation Way': way, // menu, logo
        });
    },
    menuCollapsed() {
        mixpanel.track('Teacher collapsed sidebar');
    },
    menuExpanded() {
        mixpanel.track('Teacher expanded sidebar');
    },
    dismissedWelcome() {
        mixpanel.track('Teacher dismissed welcome screen');
    },
    dismissedGoalWhy() {
        mixpanel.track('Teacher dismissed goal Why & How');
    },
    clickedWhoIsPlaying() {
        mixpanel.track('Teacher clicked Who is playing');
    },
    clickedWhoIsNotPlaying() {
        mixpanel.track('Teacher clicked Who is not playing');
    },
    completedHomeAccess() {
        mixpanel.track('Teacher completed home access step');
    },
    curriculumInfoIconClicked() {
        mixpanel.track('Teacher clicked Curriculum Skills info icon');
    },
    unlockedPremiumFeature({ feature }) {
        mixpanel.track('Teacher unlocked premium feature', {
            Feature: feature, // st, multiTbl, mistakes, printable
        });
    },
    selectedPremiumFeature({ feature }) {
        mixpanel.track('Selected feature in Referral program', {
            Feature: feature, // st, multiTbl, mistakes, printable
        });
    },
    /**
     * Tracks paid resources survey result
     * @param response string-boolean map of the survey answers
     * e.g. {
     *     "Yes - Parents": false,
     *     "Yes - School": true,
     *     "Maybe - Parents": true,
     *     "No - School": false,
     *     "No - Parents": false,
     *     "No answer": false
     * }
     */
    openToPaidResources(response) {
        mixpanel.track('Open to paid resources', response);
    },
    teacherSeesPromoBanner({ campaign, place }) {
        mixpanel.track('Teacher sees promo banner', {
            Campaign: campaign,
            Place: place,
        });
    },
    teacherClicksPromoCta({ campaign, place }) {
        mixpanel.track('Teacher clicked promo banner CTA', {
            Campaign: campaign,
            Place: place,
        });
    },
    viewedHomePracticePage() {
        mixpanel.track('Viewed Home Practice page');
    },
};
