<template>
    <teleport to="#app">
        <div class="modal-wrapper" id="ext-modal">
            <Loading v-if="!loaded" display="absolute" :solid="true" />
            <div class="modal-header">
                <ActionClose :size="ActionSize.Medium" @click="closeModal" />
            </div>
            <iframe :src="props.url" ref="iframe" />
        </div>
    </teleport>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import ActionClose from '@/student/ui/action/ActionClose.vue';
import { ActionSize } from '@/student/ui/action/Action.vue';
import Loading from '@/core/ui/_legacy/MathComponents/MathLoadingSpinner.vue';

const props = defineProps({
    url: {
        type: String,
        default: '',
        required: true,
    },
    closeModal: Function,
});

const iframe = ref();
const loaded = ref(false);

onMounted(() => {
    setTimeout(() => {
        if (!loaded.value) {
            loaded.value = true;
        }
    }, 15000);
});

watch(iframe, async (newVal) => {
    if (newVal) {
        iframe.value.onload = () => {
            loaded.value = true;
        };
    } else {
        loaded.value = false;
    }
});
</script>

<style scoped lang="scss">
.modal-wrapper {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    width: 100dvw;
    min-height: 100vh;
    min-height: 100dvh;
    top: 0;
    z-index: 100002;

    iframe {
        width: 100vw;
        width: 100dvw;
        min-height: 100vh;
        min-height: 100dvh;
        border: unset;
        overflow: auto;
        padding-bottom: 70px;
    }
}

.modal-header {
    width: 100vw;
    width: 100dvw;
    height: 80px;
    display: flex;
    justify-content: flex-end;
    padding: 0 15px;
    align-items: center;
    background: var(--Purple-700, #450c54);
    position: relative;

    @include mq($h-max-under-chromebook, max, height) {
        height: 64px;
    }

    // fix iphone eyebrow
    @media screen and (max-width: $w-max-phone) {
        height: 100px;
        padding-top: 25px;
    }
}
</style>
